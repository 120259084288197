.quickvee-single-store-main-area-detail-page {
  margin-left: 250px !important;
}

.cancleBtn-div {
  margin-top: 10px;

  display: flex;
  align-self: flex-end;
}

.cancleBtn-div {
  display: none !important;
}

@media (max-width: 1200px) {
  .quickvee-single-store-top-bar > .quickvee-logo-icon-single-store {
    /* width: auto; */
    margin-right: 10px;
    margin-left: -6px;
  }
}

.cancleBtn-div-main-order > div {
  display: flex;
  align-items: flex-end;
  /* border: 1px solid 1px red !important; */
  height: 20px !important;
}

.cancleBtn-div-main-order {
  display: none !important;
}
/* .swpier_main_div_SingleProductListing{
  margin-left: -25px;
  margin-right: -25px;
}
.swpier_main_div_SingleProductListing_inner_div{
  padding-left: 25px !important;
} */
@media (max-width: 768px) {
  .css-13xqbvb {
    top: 5px !important;
    right: 7px !important;
  }
  .quickvee-customer-login-main {
    /* width: 75px; */
  }
  .delivery-address-main-div {
    display: flex !important;
    flex-wrap: wrap;
  }
  .delivery-address-inner-div {
    margin: 0px 0px 15px 0px !important;
  }
  .quickvee-single-product-image-section {
    margin: 20px 20px 0px 0px;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    /* margin-left: 10px !important; */
  }
  .quickvee-single-store-main-area-detail-page {
    margin-left: 0 !important;
  }

  .quickvee-single-product-image-section {
    /* margin: 0px 0px 20px 20px; */
  }

  .quickvee-single-store-left-menu {
    width: 250px;
    position: fixed;
    z-index: 999;
    top: -9px;
    height: 100vh;
    padding-bottom: 70px;
    left: 0;
    transition: all 0.3s;
    overflow-x: auto !important;
    /* overflow: hidden !important; */
  }

  .cancleBtn-div {
    display: block !important;
  }

  .quickvee-single-store-top-bar > .quickvee-logo-icon-single-store {
    width: auto;
  }

  /* .quickvee-single-store-search-bar {
        display: none !important;
    }

    .quickvee-single-store-top-bar>.quickvee-logo-icon-single-store {
        width: unset !important;

    } */
  .quickvee-store-topbar-cart {
    width: unset !important;
    /* padding-bottom: 100px; */
  }

  .quickvee-store-cart-items {
    padding-bottom: 100px;
  }

  .modal-view-full-hours-div {
    width: 90%;
  }

  .modal-view-full-hours-description {
    /* margin-top: 50px; */
    /* height: 100%; */
    /* overflow-y: auto !important; */
  }

  .cancleBtn-div-main-order {
    display: flex !important;
    align-items: flex-end !important;
  }

  .cancleBtn-div-main-order > div {
    /* align-items: flex-end; */

    margin: 10px;
    width: 20px;
  }
}

.quickvee-store-cart-items {
  padding-bottom: 100px;
}

.modal-view-full-hours-description {
  overflow-y: auto !important;
  max-height: 78vh;
  scroll-behavior: smooth;
}

.modal-view-full-hours-description::-webkit-scrollbar {
  width: 3px !important;
}

.modal-view-full-hours-description::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

.modal-view-full-hours-description::-webkit-scrollbar-thumb {
  background: #3e85f8 !important;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

.modal-view-full-hours-description::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

.cancleBtn-div-main-order > div {
  /* justify-self: flex-end; */
  margin: 10px;
  width: 20px;
}

@media (min-width: 769px) {
  .quickvee-single-store-logo-singleStore {
    display: none !important;
  }
}

.quickvee-single-store-logo-singleStore {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.quickvee-single-store-logo-singleStore a img {
  object-fit: contain;
  object-position: center;
  border-radius: 100px;
  border: 2px solid #f2f2f2;
  overflow: hidden;
  text-align: center;
  padding: 10px;
  position: relative;
  width: 75px;
  height: 75px;
  left: 0px;
  top: 0px;
  transition: all 0.5s;
}

.quickvee-single-store-main-area-detail-page {
  display: block !important;
}

.quickvee-single-store-pickup-delivery {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* width: 100%; */
  background: #efefef;
  border-radius: 5px;
  /* padding: 5px 10px; */
}

.quickvee-single-store-pickup-delivery > Button {
  /* width: 100%; */
  /* padding: 3px 25px; */
  width: 120px;
  background-color: #efefef;
  color: #928c8c;
  font-size: 15px;
  text-transform: capitalize !important;
  transition: all 0.2s ease-in-out;
}

.quickvee-single-store-pickup-delivery > .active-delivery-button {
  background: #000 !important;
  color: #ffffff !important;
  font-size: 15px;
  height: 44px;
  font-family: QuicksandMedium !important;
  box-shadow: 0px 6px 12px #00000042;
}

.quickvee-single-store-content-singleStore > span {
  display: flex;
  align-items: center;
  color: #232323;
  text-transform: capitalize;
  font-size: clamp(10px, 0.05px + 5.1783vw, 18px) !important;
  /* text-align: center; */
  font-family: QuicksandBold !important;
}

@media (max-width: 652px) {
  .quickvee-single-store-logo-singleStore {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
  }

  .quickvee-single-store-pickup-delivery {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 612px) {
  .q-sv-single-product {
    width: 180px !important;
    /* height: 250px !important; */
  }

  /* .quickvee-single-store-content-singleStore>span {
        font-size: 20px;
    } */

  .quickvee-single-store-content-singleStore > p {
    font-size: clamp(10px, 0.05px + 5.1783vw, 14px) !important;
  }

  .single-store-sproductListing-section > section > span {
    font-size: 20px;
  }

  .single-store-sproductListing-section > section {
    margin-bottom: 0;
  }
}

.store-open-close-status {
  border-radius: 12px;
  color: #fff;
  font-family: QuicksandMedium !important;
  text-transform: uppercase;
  padding: 2px 10px;
  font-size: 13px;
  margin-left: 10px;
  /* margin-top: -12px; */
  z-index: 1;
}

.Swiper-btn {
  font-size: 80% !important;
  width: 90px;
  /* margin-right: 10px; */
}

.mySwiper-catagories-swiper-single-store {
  margin-bottom: 20px;
  margin-left: -20px;
  padding-left: 20px;
  margin-right: -20px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .q-sv-single-product-bottom-purchase {
    position: absolute;
    top: 5px;
    left: 0;
    display: flex !important;
    flex-direction: row-reverse;
    /* transform: translate(-50%, -50%) !important; */
  }

  .modal-form-single-item {
    /* margin-bottom: 15px !important; */
  }

  .delivery-address-update-form {
    /* margin-bottom: 15px; */
    /* display:flex !important;
    flex-wrap: wrap; */
  }
  .delivery-address-update-form-city-zipCode {
    display: flex !important;
    flex-wrap: wrap;
  }
  .modal-form-single-item-city {
    margin: 0px 0px 15px 0px !important;
    width: 100%;
  }
  .modal-form-single-item-zipcode {
    width: 100%;
  }

  .q-sv-single-product-bottom-purchase {
    position: absolute;
    top: 0px;
    left: 0;
    display: flex !important;
    flex-direction: row-reverse;
    /* transform: translate(-50%, -50%) !important; */
  }

  .q-sv-single-product {
    padding-top: 45px;
    /* padding-bottom: 45px !important; */
  }

  .q-sv-single-product > .q-sv-single-product-bottom {
    display: flex !important;
    flex-direction: column-reverse;
    /* margin-top: 20px; */
  }

  .q-sv-single-product > span {
    position: absolute !important;
    bottom: 67px !important;
    top: unset !important;
    margin-left: 10px;
    height: 20px;
    width: 60px;
    font-size: 10px;
    padding-top: 3px;
  }

  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-purchase
    > Button {
    width: 100px !important;
    font-size: 11px;
    background: none;
    margin-left: 0 !important;
    margin-right: -25px;
    height: 36px !important;
  }

  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-purchase
    > Button
    p {
    margin-left: 0;
  }

  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-purchase
    > .product-qti-button {
    width: 58%;
    margin-right: 11px;
    /* margin-top: 5px; */
  }

  .out-of-stock-btn {
    margin-right: 10px !important;
    margin-top: 5px !important;
    border: 5px solid black;
  }

  .home-page-all-sections {
    width: 94% !important;
  }

  .header_area_box {
    width: 94% !important;
  }

  .media-scroller-upper {
    margin-left: -25px !important;
    padding-left: 25px !important;
    margin-right: -25px !important;
    padding-right: 25px !important;
  }

  .q-sv-single-product > .q-sv-single-product-top {
    width: 100% !important;
    margin-top: 5px;
  }

  .q-sv-single-product > .q-sv-single-product-top > img {
    height: 100%;
  }

  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-purchase
    > .add-wish {
    background: unset;
  }

  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-amount
    > span {
    font-size: 15px;
  }

  .q-sv-single-product > .q-sv-single-product-bottom > h1 {
    font-size: 12px;
  }

  .quickvee-single-store-content-singleStore {
    margin-left: 5px;
  }

  .quickvee-single-store-content-singleStore > span {
    font-size: 20px;
  }

  .quickvee-single-store-content-singleStore > p {
    font-size: clamp(12px, 0.05px + 3.1783vw, 16px) !important;
  }

  .single-store-sproductListing-section > section > span {
    font-size: clamp(16px, 0.05px + 4.1783vw, 20px) !important;
  }

  .single-store-sproductListing-section {
    margin-bottom: 20px;
  }

  .single-store-sproductListing-section > section {
    margin-bottom: 5px;
  }

  .songleStoreSearchBar {
    margin-top: -10px;
    margin-left: -25px;
    margin-right: -25px;
  }

  .q-sv-single-product-bottom-purchase
    > .product-qti-button
    > .add-remove-cart-btn {
    background: #0a64f9 !important;
    border: none !important;
    padding: 4px 5px !important;
  }

  .single-product-card-add-btn {
    background: #0a64f9 !important;
    border: none !important;
    padding: 14px 5px !important;
    height: 28px;
    border-radius: 4px;
  }

  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-purchase
    > .product-qti-button {
    height: auto;
    width: 10px;
    padding: 0px;
    /* border: 1px solid #0a64f9; */
    border: 0;
  }

  .select-box {
    z-index: 1;
  }

  .product-in-category {
    padding-left: 5px;
    padding-right: 5px;
  }

  .modal-view-full-hours-description > .full-hours-data > div > span {
    font-size: clamp(5, 0.05px + 3.1783vw, 16px);
  }

  .modal-view-full-hours-description > .full-hours-data > div > p {
    font-size: clamp(5px, 0.05px + 3.1783vw, 16px);
  }

  .select-box__input-text {
    font-size: clamp(8px, 0.05px + 3.1783vw, 16px) !important;
  }

  .select-box__option {
    font-size: clamp(8px, 0.05px + 3.1783vw, 16px) !important;
  }

  .single-selectCategory-product-listing > .q-single-category-headings > span {
    font-size: clamp(12px, 0.05px + 5.1783vw, 20px) !important;
  }

  .order-top-bar-search {
    width: 100%;
  }

  /* #modal-view-full-hours-header {
    position: sticky !important;
    top: 0;
    background-color: white;
  } */

  .topBarUserAndCartLogo {
    display: flex !important;
    flex-direction: row-reverse;
    /* border: solid 1px red; */
    justify-content: center !important;
  }

  .select-box {
    width: 220px;
  }

  .quickvee-single-store-logo-singleStore a img {
    height: 60px;
    width: 60px;
  }

  .quickvee-store-topbar-cart > .quickvee-store-cart-checkout-btn {
    margin: 0;
    width: 100%;
    padding: 10px;
  }

  .full-hours-data-detail > .single-full-hour-data-detail > img {
    width: 50px;
    height: 50px;
  }

  .full-hours-data-detail > .single-full-hour-data-detail > span {
    font-size: clamp(8px, 0.5px + 2.1783vw, 14px) !important;
    text-align: center;
  }

  .full-hours-data-detail > .single-full-hour-data-detail > p {
    font-size: clamp(8px, 0.5px + 2.1783vw, 14px) !important;
    text-align: center;
  }

  .modal-view-full-hours-description
    > .full-hours-data-detail
    > .single-full-hour-data-detail {
    padding: 10px;
  }

  .order-side-bar-component {
    top: 0;
    z-index: 100;
  }

  .quickvee-store-topbar-cart > .quickvee-store-cart-checkout-btn > a,
  .quickvee-store-topbar-cart
    > .quickvee-store-cart-checkout-btn
    > .payment-checkout-with-login {
    width: 100%;
  }

  .quickvee-store-topbar-cart > {
    padding-left: 10px;
  }

  .orderSummeryBannerContent {
    right: unset !important;
    left: 10%;
    width: unset !important;
    font-size: clamp(18px, 0.5px + 2.1783vw, 20px);
  }

  .OrderSummeryProductRTop h4 {
    font-size: clamp(15px, 0.5px + 2.1783vw, 18px) !important;
  }

  .OrderSummeryProductRBottomL span {
    font-size: clamp(18px, 0.5px + 2.1783vw, 20px) !important;
  }

  .orderSummeryRightTopC p {
    margin-bottom: 12px !important;
  }

  .button-for-minamount-on-delivery {
    width: 100%;
  }

  .full-hours-data-detail > .single-full-hour-data-detail > img {
    width: 50px;
    height: 50px;
  }

  .full-hours-data-detail > .single-full-hour-data-detail > span {
    font-size: clamp(8px, 0.5px + 2.1783vw, 14px) !important;
    text-align: center;
  }

  .full-hours-data-detail > .single-full-hour-data-detail > p {
    font-size: clamp(8px, 0.5px + 2.1783vw, 14px) !important;
    text-align: center;
  }

  .modal-view-full-hours-description
    > .full-hours-data-detail
    > .single-full-hour-data-detail {
    padding: 10px;
  }

  .order-side-bar-component {
    top: 0;
    z-index: 100;
  }

  .quickvee-store-topbar-cart > .quickvee-store-cart-checkout-btn > a {
    width: 100%;
  }

  .quickvee-store-topbar-cart > {
    padding-left: 10px;
  }

  .orderSummeryBannerContent {
    /* right: unset !important; */
    /* left: 10%; */
    width: 65% !important;
    font-size: clamp(18px, 0.5px + 2.1783vw, 20px);
  }

  .orderSummeryBannerContent p {
    font-size: 16px;
  }

  .OrderSummeryProductRTop h4 {
    font-size: clamp(15px, 0.5px + 2.1783vw, 18px) !important;
  }

  .OrderSummeryProductRBottomL span {
    font-size: clamp(18px, 0.5px + 2.1783vw, 20px) !important;
  }

  .orderSummeryRightTopC p {
    margin-bottom: 12px !important;
  }

  .button-for-minamount-on-delivery {
    width: 100%;
  }
}

@media (max-width: 612px) {
  .q-sv-single-product {
    width: 100% !important;
    height: 300px !important;
  }
}

.add-btn-single-product {
  /* margin-right: 5px; */
  /* width: 30px; */

  color: #efefef;
  background-color: #0a64f9;
  border-radius: 5px;
  /* margin-top: 5px; */
}

.quickvee-single-store-logo-singleStore {
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 420px) {
  .quickvee-single-store-pickup-delivery {
    width: 100%;
  }

  .quickvee-single-store-pickup-delivery > Button {
    width: 180px;
  }

  .select-box {
    width: 60%;
  }

  .modal-view-full-hours-description
    > .full-hours-data-detail
    > .single-full-hour-data-detail {
    padding: 5px;
  }
}

@media (max-width: 900px) {
  .q-sv-single-product .single-p-select-variant-btn {
    width: 70%;
    padding: 0;
  }
}

@media (max-width: 806px) {
  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-purchase
    > Button
    p {
    font-size: 14px !important;
  }
}
@media (max-width: 480px) {
  .StoreCredit-zone-detail-section {
    padding-top: 0px;
    padding-left: 0%;
    padding-right: 0;
  }

  .single-store-sproductListing-section > section > div > a {
    font-size: clamp(8px, 0.05px 2.1783vw, 16px) !important;
    /* width: 90px !important; */
    white-space: nowrap;
  }

  .account-info-main-component {
    padding: 15px 0px;
  }

  .account-info-welcome-header {
    margin: 0px 15px;
  }

  .account-info-subheading > p {
    margin: 0px 15px;
  }

  .account-info {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 0;
    margin: 12px -2px;
  }

  .StoreCredit-zone-detail-section {
    border: none;
  }
}

/* @media (max-width:441px) {
    .single-store-sproductListing-section>section>div>a {
        font-size: 10px !important;
    }
} */

.search-category-page-store-description {
  /* width: auto !important; */
  /* max-width: 170px !important; */
  width: auto !important;
}

@media (max-width: 440px) {
  .search-category-page-store-description > span {
    font-size: 15px;
  }

  .search-category-page-store-description > p {
    font-size: 10px;
  }

  .search-category-page-store-detail > .search-category-store-image-div {
    margin-right: 6px;
  }
}

.modal-view-full-hours-description {
  overflow-y: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.quickvee-single-store-offer-coupon {
  /* width: 285px; */
}

.moble-cart-icon {
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  width: 28px;
  /* margin-right: 20px !important; */
}

.css-106c1u2-MuiBadge-badge {
  top: 9px !important;
  right: 10px !important;
}

.quickvee-single-store-cart-button {
  background: #fff !important;
  border-radius: 6px !important;
  color: #232323 !important;
  cursor: pointer !important;
  font-family: QuicksandBold !important;
  font-size: 20px !important;
  line-height: normal !important;
  margin: 0 5px;
  padding: 6px 20px !important;
}

.q-sv-single-product > .q-sv-single-product-top > img {
  z-index: unset !important;
}

.quickvee-single-store-top-bar {
  display: flex !important;
  flex-wrap: nowrap;
}

.topBarUserAndCartLogo {
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: row-reverse;
}

@media (max-width: 950px) {
  .quickvee-single-store-offer-coupon {
    margin: 0;
  }

  .order-top-bar-search {
    width: unset !important;
  }
}

.single-product-zoom-div > div > div {
  /* background-size: 80% !important; */
  /* background-position: 50% !important; */
}

@media (max-width: 1104px) {
  .single-product-zoom-div > div > div {
    background-size: 80% !important;
    /* background-position: 50% !important; */
  }

  .quickvee-single-product-add-update > span {
    margin: 0;
  }

  .quickvee-single-product-add-update > span {
    width: 10%;
  }

  .quickvee-single-product-add-update {
    /* margin-left: px !important; */
  }

  .quickvee-single-product-add-update > .single-patc-btn,
  .quickvee-single-product-add-update > .single-patc-btn:hover {
    padding: 5px 11px !important;
  }
}

@media (max-width: 891px) {
  .single-product-zoom-div > div > div {
    background-size: 60% !important;
    /* background-position: 50% !important; */
  }

  .quickvee-single-product-add-update > .single-patc-btn,
  .quickvee-single-product-add-update > .single-patc-btn:hover {
    margin: 0 10px !important;
  }

  .quickvee-single-product-detail-section {
    margin-left: 0;
  }
}

.quickvee-single-product-add-update > .single-patc-btn,
.quickvee-single-product-add-update > .single-patc-btn:hover {
  font-size: clamp(2px, 5px + 0.7783vw, 14px) !important;
  /* margin: 0 10px; */
}

.single-product-single-features-selection > div > label {
  font-size: clamp(12px, 1px + 1.1783vw, 14px) !important;
}

@media (max-width: 850px) {
  .quickvee-single-product-add-update {
    margin-left: 0px !important;
    margin-right: -100px !important;
  }
}

.css-106c1u2-MuiBadge-badge {
  width: 20px;
}

@media (max-width: 480px) {
  .account-info-welcome-header {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start !important;
  }

  .account-info-welcome-header p {
    /* margin-left: auto; */

    /* Add this line */
  }
}

.account-info-main-component > .account-info-welcome-header > h1 {
  font-size: clamp(15px, 2px + 4.1783vw, 25px) !important;
}

.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  padding-bottom: 5px !important;
}

#modal-modal-description {
  padding-bottom: 70px;
}
.modal-Billinginfo {
  padding-bottom: 80px !important;
}

#modal-modal-description::-webkit-scrollbar {
  width: 3px;
}

/* Track */
#modal-modal-description::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;

  cursor: pointer;
}

/* Handle */
#modal-modal-description::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
#modal-modal-description::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

.basic-modal > .modal-forms > .modal-footer {
  padding: 15px;
  /* margin-left: 12px;
  margin-right: 18px; */
  background: white;
  position: fixed !important;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 490px) {
  .quickvee-single-product-top-section {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
  }

  .quickvee-single-product-top-section > div {
    flex: 1;
    /* Make the divs flexible to distribute space */
    display: flex;
    /* Nested flex container */
  }

  .quickvee-single-product-detail-section {
    align-self: start !important;
  }

  .quickvee-single-product-image-section {
    width: 100%;
    /* margin-left: 10px; */
    margin: 0;
  }

  .single-product-single-features-selection > div > label {
    font-size: 18px !important;
    padding: 7px 14px;
  }

  .quickvee-single-product-add-update {
    margin-right: 0 !important;
  }

  .quickvee-single-product-add-update > .single-patc-btn,
  .quickvee-single-product-add-update > .single-patc-btn:hover {
    font-size: 13px !important;
    margin: 0 20px !important;
  }

  .quickvee-single-product-single-features {
    /* margin: -6px; */
  }

  .single-product-single-features-selection > div > input {
    height: 0;
  }

  .quickvee-single-product-detail-section {
    margin: 0;
  }

  .go-to-cart-btn {
    padding: 10px 20px !important;
  }
}

.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select {
  min-height: 1.543em !important;
}

.discount-tag {
  z-index: 1;
}

.id-type-billinginfo {
  height: 3.1rem !important;
  margin-top: 1px !important;
}

.password-title-accountinfo {
  text-transform: capitalize;
  color: #212121;
  font-size: 16px;
  font-family: QuicksandBold, 'Quicksand' !important;
}

.last-change-password-p {
  text-transform: capitalize;
  color: #707070 !important;
  font-size: 12px;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}

.date-of-birth-div {
  margin-bottom: 0 !important;
}

@media (max-width: 420px) {
  .out-of-stock-btn {
    height: 36px !important;
  }
}

.dropdown-container > button {
  background-color: white !important;
  border: none !important;
}
.DropDown-menu-container {
  border-radius: 8px;
  box-shadow: 0px 6px 15px #0a64f94d;
  padding: 0 !important;
}
.dropdown-toggle::after {
  display: none !important;
}

.error_banner_area {
  width: 100%;
  height: 100%;
  background: url('./../Assets/PageNotFoundImages/banner_bg.webp');
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-repeat: no-repeat;
  min-height: calc(100vh - 0px);
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .error_banner_area {
  }
}

.error_banner_left {
  width: 20%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: url('./../Assets//PageNotFoundImages/banner_left.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.error_banner_right {
  width: 20%;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: url('./../Assets/PageNotFoundImages/banner_right.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.error_banner_center {
  width: 40%;
  height: auto;
  margin: 0 auto;
  /* padding-top: 13%; */
  text-align: center;
}

element.style {
  filter: invert(0);
}
.error_banner_center img {
  width: 40%;
  height: auto;
  margin-bottom: 20px;
}

@media (max-width: 1280px) {
  .error_banner_left,
  .error_banner_right {
    width: 23%;
  }
}
@media (max-width: 991px) {
  .error_banner_left {
    background-position: right;
  }
  .error_banner_right {
    background-position: left;
  }
  .error_banner_center {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .error_banner_left,
  .error_banner_right {
    width: 36%;
  }
}
