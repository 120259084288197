@media (min-width: 1920px) {
}

@media (max-width: 1440px) {
}

@media (max-width: 1367px) {
  .q-sv-single-product .single-p-select-variant-btn {
    font-size: 13px;
  }
  .slick-initialized .slick-slide {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1281px) {
}

@media (max-width: 1280px) {
}

@media (max-width: 1279px) {
}
@media (max-width: 1200px) {
}

@media (max-width: 1024px) {
  .main-authentication-component .wrapper{
    display:none;
  }
  .main-authentication-component{
    height: 96vh;
  }
}

@media (max-width: 1023px) {
}

@media (max-width: 991px) {
}
@media (max-width: 950px) {
}
@media (max-width: 800px) {
}
@media (max-width: 768px) {
  .footer_area_inner {
    width: 90%;
  }
  .quicvee-home-banner img.DesktopBanner {
    display: none;
  }
  .quicvee-home-banner img.MobileBanner {
    display: block;
  }
  .basic-modal{
    width: 95%;
  }
  .delivery-address-update-form{
    /* display: unset; */
  }
}
@media (max-width: 767px) {
  /* .order-top-bar-search {
    display: none;
  } */
  .header_area {
    margin-bottom: 0;
  }

  .order-top-bar-search {
    justify-content: space-between;
    border-radius: 0px;
    width: 100%;
  }

  .footer_areaL {
    width: 100%;
    text-align: center;
  }
  .footer_areaR {
    width: 100%;
    text-align: center;
  }
  .footer_areaR ul {
    float: unset;
    display: inline-block;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .home_page_top_category_div_inner {
    width: 70px;
    height: 70px;
  }
  .q-single-category-link-home > span {
    font-size: 14px;
  }
}

@media (max-width: 515px) {
  .footer_area_inner {
    width: 100%;
  }
}

@media (max-width: 480px) {
}

@media (max-width: 479px) {
}

@media (max-width: 420px) {
}
@media (max-width: 419px) {
}
@media (max-width: 380px) {
}