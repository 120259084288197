@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: normal !important;
  /* -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

*,
::after,
::before {
  box-sizing: border-box;
}

.qvrow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.qvrow > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col-qv-6 {
  flex: 0 0 auto;
  width: 50%;
}

/* remove this for focus border in input border: 2px solid #0A64F9 !important; */

p {
  margin: 0;
  padding: 0;
  margin-bottom: 0px !important;
}

input:hover,
input:focus,
Input:hover,
Input:focus {
  background-color: none !important;
  background: none;
}

@font-face {
  font-family: QuicksandLight;
  src: url(../Assets/fonts/Quicksand-Light.ttf);
}

@font-face {
  font-family: QuicksandBold;
  src: url(../Assets/fonts/Quicksand-Bold.ttf);
}

@font-face {
  font-family: QuicksandMedium;
  src: url(../Assets/fonts/Quicksand-Medium.ttf);
}

@font-face {
  font-family: QuicksandRegular;
  src: url(../Assets/fonts/Quicksand-Regular.ttf);
}

@font-face {
  font-family: QuicksandSemiBold;
  src: url(../Assets/fonts/Quicksand-SemiBold.ttf);
}

@font-face {
  font-family: QuicksandLight;
  src: url(../Assets/fonts/Quicksand-Light.ttf);
}

/* 
@font-face {
  font-family: QuicksandBold;
  src: url(https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap);
}

@font-face {
  font-family: QuicksandMedium;
  src: url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
}

@font-face {
  font-family: QuicksandRegular;
  src: url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
}

@font-face {
  font-family: QuicksandSemiBold;
  src: url(https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap);
} */

/* main authentication component */
.main-authentication-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.wrapper {
  width: 55%;
  /* margin-left: auto; */
  border-radius: 15px;
  background: url('../Assets/LoginScreen/LoginBanner1.1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-self: stretch;
  height: 95vh;
  animation: slider 10s infinite;
}

.my-overflow-hanlde-for-authentication {
  padding: 20px 60px 20px 0;
  height: 95vh;
  justify-content: flex-start !important;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

@media (max-width: 1024px) {
  .my-overflow-hanlde-for-authentication {
    overflow-y: unset;
    scroll-behavior: smooth;
    overflow-x: unset;
  }
}

/* width */
.my-overflow-hanlde-for-authentication::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.my-overflow-hanlde-for-authentication::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
.my-overflow-hanlde-for-authentication::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.my-overflow-hanlde-for-authentication::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

@keyframes slider {
  0% {
    background: url('../Assets/LoginScreen/LoginBanner1.1.png');
    background-size: cover;
    background-position: center;
  }

  30% {
    background: url('../Assets/LoginScreen/LoginBanner2.1.png');
    background-size: cover;
    background-position: center;
  }

  60% {
    background: url('../Assets/LoginScreen/LoginBanner3.1.png');
    background-size: cover;
    background-position: center;
  }

  100% {
    background: url('../Assets/LoginScreen/LoginBanner1.1.png');
    background-size: cover;
    background-position: center;
  }
}

/*Customer Login*/
@media screen and (max-width: 900px) {
  .my-overflow-hanlde-for-authentication {
    /* overflow-x: hidden; */
    padding: 20px 40px 20px 0;
  }
}

@media screen and (max-width: 750px) {
  .login-customer-form {
    margin-right: 20px;
  }
}

@media screen and (max-width: 678px) {
  .wrapper {
    display: none;
  }

  .my-overflow-hanlde-for-authentication {
    /* overflow: auto; */
    padding: 0px;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    display: none;
  }

  .quickvee-logo-authentication {
    width: 150px !important;
  }

  .back-auth-icons {
    top: 7px !important;
    left: 1% !important;
  }
}

.quickvee-logo-authentication {
  width: 200px;
  margin: 0 0 30px 0;
}

.css-18djxyj-MuiPaper-root-MuiAlert-root {
  padding: 6px 10px !important;
}

.login-customer-form {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.input-error {
  /* display: inherit; */
  /* right: 0; */
  margin: 3px !important;
  padding: 0px !important;
  /* float: left !important; */
  /* justify-self: right; */
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  font-size: 11px !important;
  color: #fa0000 !important;
}

.login-customer-form > h1 {
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 19px;
  color: #222222 !important;
}

.login-customer-form .sub-heading-from {
  font-family: QuicksandMedium, 'Quicksand' !important;
  font-size: 14px;
  color: #232323 !important;
  margin: 10px 0 25px 0;
}

.sub-heading-from {
  width: 300px;
}

.password-input-field {
  display: flex;
  max-width: 100%;
  width: 300px;
  font-size: 12px !important;
  background: #ffffff;
  font-family: QuicksandMedium, 'Quicksand' !important;
  /* background: #FFFFFF !important;
  border: 1px solid #D3D3D3 !important;
  border-radius: 6px !important; */
}

.password-input-field:focus-within {
  border: 2px solid #0a64f9 !important;
}

.customer-login-input {
  max-width: 100%;
  width: 300px;
  font-size: 12px !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
  /* background: #FFFFFF !important;
  border: 1px solid #D3D3D3 !important;
  border-radius: 6px !important; */
}

.customer-login-input .css-1njz7x1,
.password-input-field .css-1njz7x1,
.payment-billing-input .css-1njz7x1 {
  font-size: 13px !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
  color: #595959 !important;
}

.customer-login-input .css-dvhgws,
.password-input-field .css-dvhgws,
.payment-billing-input .css-dvhgws {
  background: #ffffff !important;
  /* border: 1px solid #d3d3d3 !important; */
  border-radius: 6px !important;
}

.css-s2lqj8,
.css-s2lqj8.Mui-focused {
  font-size: 13px !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
}

.modal-form-single-input .css-s2lqj8,
.modal-form-single-input .css-ftr4jk {
  font-size: 13px !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  color: #000000;
}

.modal-form-single-item .css-17gq4vl.MuiSelect-select {
  font-size: 13px !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  color: #000000 !important;
}

.modal-form-single-item .css-17gq4vl:focus {
  background-color: #fff !important;
}

.modal-form-single-item .css-1thjcug:hover {
  background-color: #fff !important;
}

.css-1thjcug:hover {
  background-color: #fff !important;
}

.css-fi4tqy {
  padding: 14px 12px 4px !important;
  font: unset !important;
  font-size: 15px !important;
  font-family: QuicksandSemiBold !important;
}

.modal-form-single-input .css-fi4tqy {
  padding: 18px 12px 4px !important;
  font: unset !important;
  font-size: 15px !important;
  height: 1.98rem;
  font-family: QuicksandSemiBold !important;
}
.css-2bxn45 {
  padding: 22px 12px 8px !important;
}
.customer-login-input > div .css-dvhgws:hover,
.customer-login-input .css-dvhgws.Mui-focused,
.password-input-field > div .css-dvhgws:hover,
.password-input-field .css-dvhgws.Mui-focused {
  background: #ffffff !important;
}

.password-input-field
  .css-dvhgws:hover:not(
    .Mui-disabled,
    .password-input-field .Mui-error
  )::before,
.password-input-field .css-dvhgws:hover:not(.Mui-disabled, .Mui-error)::before,
.customer-login-input
  .css-dvhgws:hover:not(
    .Mui-disabled,
    .customer-login-input .Mui-error
  )::before,
.customer-login-input .css-dvhgws:hover:not(.Mui-disabled, .Mui-error)::before {
  border-bottom: none !important;
}

.customer-login-input .css-dvhgws::before,
.password-input-field .css-dvhgws::before,
.payment-billing-input .css-dvhgws::before {
  border-bottom: none !important;
}

.css-dvhgws::after {
  border-bottom: none !important;
}

/* .customer-login-error-input{
  border: 1px solid #F55353 !important;
} */

.customer-login-input:focus-within,
.password-input-field:focus-within {
  border: 2px solid #0a64f9 !important;
  border-radius: 6px !important;
}

.modal-form-single-input .css-1thjcug.Mui-focused {
  background-color: #fff !important;
}

.modal-form-single-input .css-1thjcug {
  background-color: #fff !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover {
  border-color: #911b1b !important;
}

.css-17mgbju::before,
.css-1thjcug::before,
.css-17mgbju::after,
.css-1thjcug::after {
  border: none !important;
}

.css-1ff8729-MuiInputBase-root-MuiFilledInput-root {
  background: none !important;
  top: 8px !important;
  font-size: 14px !important;
}

.css-1ff8729-MuiInputBase-root-MuiFilledInput-root:before {
  border: none !important;
}

.css-1ff8729-MuiInputBase-root-MuiFilledInput-root:after {
  border: none !important;
}

.css-1mog9k0-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 13px !important;
  color: #595959 !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
}

.css-11qvwfa-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
}

.css-11qvwfa-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  font-size: 14px !important;
  color: #595959 !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
}

.css-c5v1qu-MuiInputBase-input-MuiFilledInput-input:focus,
.css-c5v1qu-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 13px !important;
  padding-bottom: 10px !important;
  font-family: QuicksandSemibold, 'Quicksand' !important;
}

.css-yf8vq0-MuiSelect-nativeInput {
  font-family: QuicksandSemibold, 'Quicksand' !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list > li {
  /* color: #0A64F9 !important; */
  font-size: 12px;
  font-family: QuicksandSemibold, 'Quicksand' !important;
}

.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
  font-family: QuicksandSemibold, 'Quicksand' !important;
}

.input-outer-div {
  position: relative;

  display: flex;
  flex-direction: column;
  margin: 9px 0;
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
  color: #595959 !important;
}

.error-on-submit {
  align-self: flex-start !important;
  text-transform: capitalize;
  font-family: QuicksandRegular, 'Quicksand' !important;
  font-size: 11px !important;
  color: #fa0000 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-focusVisible {
  background-color: #fff !important;
}

.form-submit-error-message {
  position: relative;
  right: 0px;
  bottom: -15px;
  animation: slideTopError 0.8s;
}

.css-1pxa9xg-MuiAlert-message {
  text-transform: capitalize !important;
  font-family: QuicksandSemibold !important;
}

@keyframes slideTopError {
  0% {
    opacity: 0;
    right: 0px;
    bottom: -35px;
  }

  100% {
    opacity: 1;
    right: 0px;
    bottom: -8px;
  }
}

.error-close-icon {
  color: #000 !important;
  background-color: #fff !important;
}

.customer-login-input::placeholder {
  color: #595959 !important;
}

/* .password-input-field {
  display: flex;
  max-width: 100%;
  width: 300px;
  font-size: 10px !important;
  background: #FFFFFF;
  border-radius: 6px !important;
  font-family: QuicksandMedium !important;
  border: 2px solid #D3D3D3;
  border-radius: 6px;
} */
.unvisible-input {
  display: none !important;
}

.customer-banner-authentication {
  display: flex;
  align-items: 'center' !important;
  justify-content: space-around !important;
  flex-direction: row !important;
  flex-wrap: nowrap;
  vertical-align: middle !important;
  margin: 3vh 3vw;
}

.password-input-text,
.password-input-text:hover {
  outline-style: none;
  border-style: none;
  border: none;
  color: #595959 !important;
  font-size: 14px;
}

.css-c5v1qu-MuiInputBase-input-MuiFilledInput-input {
  color: #000000 !important;
  font: normal !important;
  letter-spacing: normal !important;
}

.forgot-password {
  align-self: end !important;
  font-size: 14px !important;
  text-decoration: underline !important;
  color: #0a64f9 !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
}

.customer-btn {
  width: 100%;
  width: 300px;
  margin: 20px 0 !important;
  background-color: #0a64f9 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  height: 44px;
}

.sign-in-section-head {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #222222;
  font-size: 14px;
  font-family: QuicksandRegular, 'Quicksand' !important;
}

.sign-in-section-head > p {
  color: #222222;
  font-size: 13px;
  font-family: QuicksandRegular, 'Quicksand' !important;
}

.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root,
.css-yjsfm1 > span {
  font-size: 13px !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
  color: #595959 !important;
}

.sign-in-section-head > span > hr {
  margin: 0 15px;
  rotate: 90deg;
  height: 15px;
  color: #707070;
}

.customer-login-with-google {
  display: flex;
  width: 100%;
  width: 300px;
  margin: 20px 0 !important;
  color: #222222 !important;
  text-transform: capitalize !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px !important;
  background: #ffffff !important;
  border: 2px solid #d3d3d3;
  border-radius: 6px;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}

.customer-login-with-google > img {
  position: absolute !important;
  right: 0 !important;
  margin-right: 15px;
}

.login-customer-reduirection {
  font-family: QuicksandMedium, 'Quicksand' !important;
  letter-spacing: 0px;
  color: #595959;
  font-size: 13px;
  margin: 5px 0 !important;
}

.login-customer-reduirection > .login-customer-reduirection-link {
  font-family: QuicksandBold, 'Quicksand' !important;
  color: #222222 !important;
  font-weight: 800 !important;
  margin: 0 2px;
  font-size: 13px;
  text-decoration: none !important;
}

.back-auth-icons {
  position: absolute;
  top: 25px;
  left: 5%;
  background: none !important;
  color: black;
}

.login-customer-reduirection > .login-customer-reduirection-link:hover {
  text-decoration: none !important;
}

/*end Customer Login*/

/* start Customer Register */

.register-inputs-div {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.c-register {
  width: 145px;
}

/* end customer registration */

/* start order history */
/* start top bar */

.main-order-top-bar {
  padding: 10px 3px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: sticky !important;
  top: 0 !important;
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;
  z-index: 99;
}

@media (min-width: 768px) {
  .main-order-top-bar {
    padding: 10px 20px 10px 0px !important;
  }
}
.quickvee-logo-order-top-bar {
  width: 148px;
  height: 40px;
  margin-right: 0px;
}

.order-top-bar-search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f6f7f8;
  border-radius: 6px;
  /* padding: 2px 10px; */
  padding-left: 3%;
  padding-right: 3%;
  width: 35vw;
}

.online-o-page-profile-drop-btn {
  margin: 0px 0px 0px 8px;
}

@media (max-width: 768px) {
  .online-o-page-profile-drop-btn {
    display: none !important;
  }
}
.my-profile-drop-btn {
  margin: 0px 11px 0 8px;
}

.my-profile-drop-btn img {
  width: 20px;
}

.top-bar-button {
  flex-grow: 1;
  color: #232323 !important;
  font-family: QuicksandBold, 'Quicksand' !important;
  text-transform: capitalize !important;
}

.order-top-bar-search > .order-top-bar-search-input {
  background: #f6f7f8;
  padding: 3px 10px;
  width: 100% !important;
}

.order-top-bar-search > .order-top-bar-search-input,
.order-top-bar-search > .order-top-bar-search-input:focus,
.order-top-bar-search > .order-top-bar-search-input:active,
.order-top-bar-search > .order-top-bar-search-input:focus-within,
.order-top-bar-search > .order-top-bar-search-input:focus-visible {
  border: none;
  border-color: none !important;
}

.order-top-bar-search > .order-top-bar-search-input::placeholder {
  font-family: QuicksandRegular, 'Quicksand' !important;
  font-size: 15px;
  color: #a8a8a8;
}

.css-q0jhri-MuiInputBase-root-MuiInput-root {
  font-family: QuicksandSemibold, 'Quicksand' !important;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type='search']:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

.order-top-bar-search-icon {
  rotate: 90deg;
  margin-right: 5px;
}

.order-top-bar-search-input::after,
.order-top-bar-search-input::before {
  border: none !important;
}

/* end top bar */

/* start side bar */

.order-side-bar-component {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 250px;
  overflow: hidden;
  height: 100%;
  border-right: 1px solid #ebebeb;
  position: fixed;
  background-color: #fff;
  /* padding: 20px 0 0 0; */
  transition: all 0.3s ease-in-out;
  left: 0;
}

@media (min-width: 768px) {
  .order-side-bar-component {
    width: 250px !important;
    transition: width 0.3s ease-in-out;
    /* Adjust width for smaller screens */
  }
}

@media (max-width: 767px) {
  .order-history-main-component {
    margin-left: 0px !important;
  }

  .order-history-main-component > .order-history-top-component > h1 {
    font-size: 18px !important;
  }

  .basic-modal {
    width: 90%;
  }
}

.order-side-bar-component > .order-side-bar-link,
.order-side-bar-component > div .order-side-bar-link-div {
  text-decoration: none !important;
}

.order-side-bar-component > .order-side-bar-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 10px 0px 20px;
  padding: 11px 10px;
  color: #2e2e2e;
}

.order-side-bar-component > .order-side-bar-link:hover {
  color: #6e81e2;
  background: #f1f6ff;
  border-radius: 5px;
}

.order-side-bar-component > .order-side-bar-link > .link-home {
  background: #2e2e2e;
  width: 20px;
  height: 200%;
  mask: url('../Assets/OrderScreen/HomeIcon.svg') no-repeat 0px;
  transition: all 0.2s ease-in;
}

.order-side-bar-component > .order-side-bar-link:hover > .link-home {
  background: #6e81e2;
  mask: url('../Assets/OrderScreen/HomeIcon.svg') no-repeat 0px;
}

.order-side-bar-component > .order-side-bar-link > .link-reorder {
  background: #2e2e2e;
  width: 20px;
  height: 200%;
  mask: url('../Assets/OrderScreen/ReorderIcon.svg') no-repeat 0px;
  transition: all 0.2s ease-in;
}

.order-side-bar-component > .order-side-bar-link:hover > .link-reorder {
  background: #6e81e2;
  mask: url('../Assets/OrderScreen/ReorderIcon.svg') no-repeat 0px;
}

.order-side-bar-component > .order-side-bar-link > .link-wishlist {
  background: #2e2e2e;
  width: 20px;
  height: 200%;
  mask: url('../Assets/OrderScreen/FavouriteIcon.svg') no-repeat 0px;
  transition: all 0.2s ease-in;
}

.order-side-bar-component > .order-side-bar-link:hover > .link-wishlist {
  background: #6e81e2;
  mask: url('../Assets/OrderScreen/FavouriteIcon.svg') no-repeat 0px;
}

.order-side-bar-component > .order-side-bar-link > span {
  color: #2e2e2e;
  margin-left: 10px;
  font-family: QuicksandSemibold, 'Quicksand' !important;
  font-size: 14px;
  padding: 0 10px;
}

.order-side-bar-component > .order-side-bar-link:hover > span {
  color: #6e81e2;
}

.order-side-bar-component > div .order-side-bar-link-div {
  display: flex;
  align-items: center;
  color: #2e2e2e !important;
  font-family: QuicksandSemibold !important;
  padding: 10px 0px !important;
  border-left: 3px solid #ffffff;
  padding-left: 20px !important;
  font-size: 15px;
}

.order-side-bar-component > div {
  display: flex;
  /* margin-top: 20px; */
  flex-direction: column;
  width: 100% !important;
  transition: all 2s ease-in-out;
}

.order-side-bar-component > div .order-side-bar-link-div {
  transition: all 0.3s ease-in-out;
}

/* .order-side-bar-component > div .order-side-bar-link-div:hover{
  background: #F5F5F5;
  border-left: 3px solid #222222;
  color: #232323;
  font-family: QuicksandBold !important;
} */

.order-side-bar-component > div .orderhistoryclass,
.order-side-bar-component > div .accountinfoclass,
.order-side-bar-component > div .homeclass,
.order-side-bar-component > div .LoyaltyPointsZone {
  background: #f5f5f5;
  border-left: 3px solid #222222;
  color: #232323;
  font-family: QuicksandBold, 'Quicksand' !important;
}

/* end side bar */

/* start main order page */
.side-bar-and-order-section {
  display: flex;
  background: #ffffff;
}

.order-history-main-component {
  width: 100%;
  padding: 20px 20px 40px 20px;
  margin-left: 250px;
  min-height: calc(100vh - 137px) !important;
  /* height: 85vh; */
}

.order-history-main-component > .order-history-top-component {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order-history-main-component > .order-history-top-component > h1 {
  font-size: 24px;
  /* margin-left: 10px; */
  color: #212121;
  font-family: QuicksandBold, 'Quicksand' !important;
}

.order-history-main-component
  > .order-history-top-component
  > .order-history-button-grp {
  background: #efefef;
  border-radius: 5px;

  padding: 6px 6px;
}

.order-history-main-component
  > .order-history-top-component
  > .order-history-button-grp
  > Button {
  background: #efefef;
  color: #928c8c;
  text-transform: capitalize;
  font-size: 14px;
  font-family: QuicksandMedium, 'Quicksand' !important;
  transition: all 0.3s ease-in-out;
  padding: 6px 15px !important;
}

@media (max-width: 496px) {
  .order-history-main-component
    > .order-history-top-component
    > .order-history-button-grp
    > Button {
    font-size: 10px;
  }

  .order-history-main-component > .order-history-top-component {
    width: 100%;
  }
}

.order-history-main-component
  > .order-history-top-component
  > .order-history-button-grp
  > Button.openorders,
.order-history-main-component
  > .order-history-top-component
  > .order-history-button-grp
  > Button.closeorders {
  color: #ffffff;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000042;
}

.order-history-bottom-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0;
}

.no-open-and-close-order {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-open-and-close-order img {
  width: 80px;
  margin-bottom: 20px;
}

.no-open-and-close-order h1 {
  font-family: QuicksandSemiBold !important;
  font-size: 19px;
  color: #232323;
}

.no-open-and-close-order p {
  font-family: QuicksandSemiBold !important;
  font-size: 13px;
  color: #878787;
  margin-bottom: 15px;
}

.no-open-and-close-order .start-shopping-on-order {
  color: #0a64f9;
  width: 200px;
  border: 2px solid #0a64f9;
  background: #fff;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
  text-transform: capitalize !important;
  background: #ffffff;
  border-radius: 5px;
}

.start-shopping-on-order:hover {
  background: #fff !important;
}

.start-shopping-on-order a {
  text-decoration: none;
  color: #0a64f9;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
}

/* end main order page*/

/*  start order Account info */

.account-info-main-component {
  padding: 20px 40px;
  margin-left: 250px;
  width: 60%;
}

@media screen and (max-width: 1200px) {
  .account-info-main-component {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .account-info-main-component {
    /* margin-left: 2; */
    margin: 0 auto;
    padding: 15px;
    /* display: flex; */
    flex-wrap: wrap;
    max-width: 100%;
  }
}

.account-info-main-component > .account-info-welcome-header {
  display: flex;
  align-items: flex-end;
  padding: 30px;
  justify-content: space-between;
  background: var(--unnamed-color-0a64f9) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-707070);
  background: url('../Assets/MyInformation/Infobackground.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #707070;
  border-radius: 5px;
}

.account-info-main-component > .account-info-welcome-header > h1 {
  color: #ffffff;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  font-size: 25px;
}

.account-info-main-component > .account-info-welcome-header > p {
  color: #ffffff;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  font-size: 15px;
}

.account-info-subheading {
  color: #707070;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  font-size: 10px;
  margin: 15px 0;
}

.account-info-subheading > p {
  color: #707070;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  font-size: 12px;
}

.account-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 15px 20px;
  margin: 20px 0;
}

.account-info > .account-info-top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-info > .account-info-top-section > span {
  text-transform: capitalize;
  color: #212121;
  font-size: 16px;
  font-family: QuicksandBold, 'Quicksand' !important;
}

.account-info-bottom-section {
  width: 100%;
}
.account-info > .account-info-bottom-section > div {
  /* display: flex; */
  align-items: top;
  padding: 10px 10px 10px 0;
  /* margin: 10px 10px 10px 0; */
  /* margin-left: 5px; */
  margin-bottom: 5px;
  /* justify-content: flex-start; */
}

@media (min-width: 1200px) {
  .css-1lj5egr-MuiGrid-root {
    max-width: 32.33% !important;
  }
}

@media (min-width: 900px) {
  .css-1lj5egr-MuiGrid-root {
    max-width: 32.33% !important;
  }
}

.account-info > .account-info-bottom-section > .account-info-single {
  display: flex;
  /* align-items: center; */
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #e1e1e1;
}

@media (max-width: 480px) {
  .span-before-driving-licence-image {
    display: none;
  }
  .account-info-single {
    justify-content: center;
  }
}

.account-info > .account-info-bottom-section > div > span:first-child {
  color: #707070;
  font-size: 13px;
  font-family: QuicksandSemibold, 'Quicksand' !important;
  width: 30%;
}

.account-info > .account-info-bottom-section > div > .driving-licence-image {
  width: 299px;
  border-radius: 5px;
}

.account-info > .account-info-bottom-section > div > span:nth-child(2) {
  color: #232323;
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 13px;
  justify-self: flex-end;
  width: 70%;
  text-transform: capitalize !important;
}

.card-info-important {
  color: #707070;
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 13px;
}

.basic-modal {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  /* width: 100%; */
  box-shadow: 1px 1px 6px black;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 10px 0;
  max-height: 94vh;
  overflow: hidden;
}

.for-scroll {
  overflow-y: scroll !important;
}

.basic-modal > #modal-modal-title {
  padding: 5px 15px 11px 15px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #d3d3d3;
}

.basic-modal > #modal-modal-title > span {
  color: #212121;
  text-transform: capitalize;
  font-size: 20px;
  font-family: QuicksandBold, 'Quicksand' !important;
}

@media (max-width: 767px) {
  .basic-modal > #modal-modal-title > span {
    font-size: 18px;
  }
}

.basic-modal > #modal-modal-title > .modal-close-icon {
  cursor: pointer;
}

.basic-modal > .modal-forms {
  padding: 15px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
}

.modal-forms .css-13sljp9 {
  width: 100%;
}

.basic-modal .modal-forms > .css-1nrlq1o-MuiFormControl-root {
  width: 100%;
  margin-bottom: 15px;
}

.modal-form-single-item {
  display: flex !important;
  flex-direction: column !important;
  margin: 0px 0px 10px !important;
  width: 100% !important;
}

.password-margin-bottom {
  margin-bottom: 15px !important;
}

.modal-form-single-item .css-14x1nlk,
.modal-form-single-item .css-14x1nlk {
  color: #595959 !important;
  font-family: QuicksandMedium, Quicksand !important;
  font-size: 13px !important;
}

.modal-form-single-item .css-1rgmeur {
  color: #595959 !important;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
}

.modal-form-single-input label {
  color: #595959 !important;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
}

.modal-form-single-input {
  width: 100%;
  font-size: 12px !important;
  background: #ffffff;
  font-family: QuicksandMedium, 'Quicksand' !important;
  background: #ffffff !important;
  border: 1px solid #d3d3d3 !important;
  border-radius: 6px !important;
}

.modal-form-single-input .css-dvhgws {
  background: #ffffff !important;
  /* border: 1px solid #D3D3D3 !important; */
  border-radius: 6px !important;
}

.modal-form-single-input .css-dvhgws:hover,
.modal-form-single-input .css-dvhgws.Mui-focused {
  background: #ffffff !important;
}

.modal-form-single-input
  .css-dvhgws:hover:not(
    .Mui-disabled,
    .modal-form-single-input .Mui-error
  )::before,
.modal-form-single-input
  .css-dvhgws:hover:not(.Mui-disabled, .Mui-error)::before {
  border-bottom: none !important;
}

.modal-form-single-input .css-dvhgws::before {
  border-bottom: none !important;
}

.modal-form-single-input .css-1njz7x1 {
  font-size: 13px !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
  color: #595959 !important;
}

.modal-form-single-input:focus-within {
  border: 1px solid #000000 !important;
}

.modal-form-single-input::placeholder {
  color: #595959 !important;
}

.modal-main-button {
  color: #0a64f9 !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  min-width: unset !important;
}
.basic-modal > .modal-forms > .modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 14px 0 0 0;
}

.basic-modal > .modal-forms > .modal-footer > .modal-cancle-btn {
  background: #ffffff !important;
  border: 1px solid #232323 !important;
  border-radius: 6px !important;
  color: #232323 !important;
  text-transform: capitalize !important;
  font-family: QuicksandBold, 'Quicksand' !important;
  width: 48%;
  height: 44px;
}

.basic-modal > .modal-forms > .modal-footer > .modal-save-btn {
  background: #0a64f9;
  border: 2px solid #0a64f9 !important;
  border-radius: 6px;
  color: #ffffff;
  text-transform: capitalize !important;
  font-family: QuicksandBold, 'Quicksand' !important;
  width: 48%;
  height: 44px;
}

.account-info > .account-info-top-section > .account-password-top-section {
  display: flex;
  align-items: center;
}

.account-info
  > .account-info-top-section
  > .account-password-top-section
  > span {
  text-transform: capitalize;
  color: #212121;
  font-size: 16px;
  font-family: QuicksandBold, 'Quicksand' !important;
}

.account-info > .account-info-top-section > div > p {
  text-transform: capitalize;
  color: #707070 !important;
  font-size: 12px;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  /* margin-left: 86px !important; */
}

.account-info-bottom-section .single-delivery-address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  /* width: 31%; */
  padding: 10px !important;
}

.account-info-bottom-section
  .single-delivery-address
  > .single-delivery-address-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.account-info-bottom-section
  .single-delivery-address
  > .single-delivery-address-content {
  width: 100%;
  margin: 10px 0;
  text-transform: capitalize;
}

.account-info-bottom-section
  .single-delivery-address
  > .single-delivery-address-footer {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
}

.account-info-bottom-section
  .single-delivery-address
  > .single-delivery-address-footer
  > .btn-remove-delivery-address
  > .delete-address-icon {
  width: 15px;
  height: 15px;
  margin: 5px;
}

.account-info-bottom-section
  .single-delivery-address
  > .single-delivery-address-footer
  > * {
  text-transform: capitalize !important;
  color: #222222 !important;
}

.my-custom-class-for-file-input::-webkit-file-upload-button,
.file-input-not-display::-webkit-file-upload-button {
  visibility: hidden !important;
}

.custom-file-upload-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 2px 15px 2px 15px;
  color: #fff;
  width: 100%;
}

/* .my-custom-class-for-file-input {
  color: #232323;
  font-family: QuicksandBold, 'Quicksand' !important;
  border-bottom: none;
  width: 50%;
} */

/* .my-custom-class-for-file-input::placeholder {
  color: red !important;
} */
.image-input-quickvee > .my-file-custom-input-for-image {
  color: #232323;
  font-size: 13px;
  font-family: QuicksandBold, 'Quicksand' !important;
  padding: 13px 13px 13px 0;
  /* cursor: pointer !important; */
  cursor: default;
  overflow: hidden;
  width: 70%;
}

/* .file-input-not-display::after{
  content: "Upload ID Card";
  color: #FFFFFF;
  font-family: QuicksandMedium !important;
  font-size: 14px ;
  background: #232323 0% 0% no-repeat padding-box;
border-radius: 3px;
padding: 7px 20px;
position: absolute;
right: 9px;
top: 10px;
cursor: pointer;
}  */

.button-update-id-card,
.button-update-id-card:hover {
  color: #0a64f9 !important;
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 14px !important;
  /* margin-right: 7px !important; */
  background-color: #fff !important;
  cursor: pointer;
  text-transform: capitalize !important;
}

.button-update-id-card img {
  margin-left: 5px !important;
}

.image-input-quickvee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.account-info-bottom-section
  .single-delivery-address
  > .single-delivery-address-footer
  > .default-address {
  color: #0a64f9 !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  background: #d2e3ff !important;
  border-radius: 4px !important;
  font-size: 10px !important;
}

.account-info-bottom-section
  .single-delivery-address
  > .single-delivery-address-footer
  > .set-default-address {
  color: #0a64f9 !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  background: none !important;
  border-radius: 4px !important;
  font-size: 10px !important;
}

.btn-remove-delivery-address {
  margin: 0px !important;
  padding: 0px !important;
}

.remove-delivery-address {
  font-weight: 600;
  font-family: QuicksandRegular !important;
  line-height: 15px;
  color: #222222;
  font-size: 12px !important;
  border-bottom: 1px solid #222222;
  border-radius: unset !important;
}

.single-delivery-address .single-delivery-address-content > .receiver-name,
.receiver-address {
  color: #232323 !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  font-size: 13px;
  margin: 3px 0;
}

.single-delivery-address
  > .single-delivery-address-content
  > .receiver-zipcode {
  color: #212121 !important;
  font-family: QuicksandRegular !important;
  font-size: 13px;
  text-transform: capitalize;
}

.delivery-address-update-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 15px;
}

.delivery-address-update-form > div {
  width: 100%;
}

.delivery-address-update-form > div:first-child {
  margin: 0px 10px 0px 0px;
}

.delivery-city-state-update-inputs {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 55%;
}

.delivery-city-state-update-inputs label {
  color: #595959 !important;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
}

.delivery-city-state-update-inputs > div:first-child {
  margin: 0px 10px 0px 0px !important;
  width: 100% !important;
}

.delivery-address-update-form .css-1gtlbg0 {
  padding: 2px 0px 2px 0px !important;
}

.the-state-input-in-delivery::before {
  content: 'âŒ„';
  font-size: 24px !important;
  position: absolute;
  right: 9px;
  top: 2px;
  color: #595959;
}

.modal-form-single-item .css-17gq4vl {
  padding: 22px 10px 7px 10px !important;
}

.the-state-input-in-delivery:focus-within::before {
  content: '';
  font-size: 0px !important;
}

.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before {
  border: none !important;
}

.css-llrb4p-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root::after {
  border: none !important;
}

.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 12px !important;
  font-family: QuicksandMedium !important;
  top: -4px !important;
}

.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  padding: 20px 10px 3px 9px !important;
  font-size: 13px;
  font: normal !important;
  color: #000000;
  font-family: QuicksandSemibold !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
  font-family: QuicksandSemiBold !important;
  top: -3px !important;
}

.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input:focus {
  /* padding: 24px 10px 0px 9px !important;
  font-size: 13px; */
  background: none;
}

.edit-delivery-address-button {
  padding: 0 !important;
  margin: 0 !important;
  color: #000 !important;
  width: 100% !important;
  min-width: 30px !important;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root {
  background: none !important;
  font-size: 14px !important;
  padding-top: 7px !important;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root:after {
  border: none !important;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root::before {
  border: none !important;
}

.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
  padding: 20px 10px 6px 9px !important;
  font-family: QuicksandSemiBold !important;
  font-size: 13px !important;
}

.css-1869usk-MuiFormControl-root {
  min-width: 110px !important;
}

.css-1xhypcz-MuiStack-root {
  padding-top: 0px !important;
  overflow: unset !important;
}

.css-1n8xjfr-MuiFormControl-root {
  margin: 3px !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #595959 !important;
}

.crop-image-modal-button {
  margin: 10px 0 !important;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  width: 100%;
}

.reactEasyCrop_Container {
  position: relative !important;
  height: 200px !important;
  width: 317px !important;
}

.my-image-crop-modal-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.crop-image-modal-button > .image-motion-button {
  background: #0a64f9 !important;
  text-transform: capitalize !important;
  color: #fff !important;
  margin: 0px 13px 0px 5px;
  padding: 5px 11px;
  border-radius: 4px;
  font-family: QuicksandMedium !important;
}

/*  end order Account info */

/* single order start */

.single-open-order-div {
  display: flex;
  flex-direction: column;
  background: url('../Assets/OrderScreen/OrderBackground.svg');
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  transition: all 0.3s ease-in;
}

.single-open-order-div:hover {
  box-shadow: 0px 6px 18px #0000001c;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

/* .infinite-scroll-component> div {
  margin: 10px;
} */

.onload-spinner-order {
  display: inline-block;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #6e6e6e;
  width: 80px;
  height: 80px;
  align-self: center;
  justify-self: center !important;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.infinite-scroll-component {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.infinite-scroll-component span {
  /* width: 49%; */
  cursor: pointer;
}

.single-open-order-div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.single-open-order-div > .single-open-order-delivery-status {
  padding-bottom: 10px;
  border-bottom: 1px dashed #e1e1e1;
  margin-bottom: 3px;
}

.single-open-order-div > .single-open-order-delivery-status > .delivery-button,
.single-open-order-div
  > .single-open-order-delivery-status
  > .picked-up-button {
  padding: 6px !important;
  text-transform: capitalize;
  font-size: 12px;
  font-family: QuicksandBold, 'Quicksand' !important;
  border-radius: 3px;
}

.single-open-order-div
  > .single-open-order-delivery-status
  > .delivery-button
  > img,
.single-open-order-div
  > .single-open-order-delivery-status
  > .picked-up-button
  > img {
  padding: 0 4px 0 0;
}

.single-open-order-div
  > .single-open-order-delivery-status
  > .picked-up-button {
  background: #ffdcc1;
  color: #db5f00;
}

.single-open-order-div > .single-open-order-delivery-status > .delivery-button {
  background: #e2edff;
  color: #0055ff;
}

.single-open-order-div > .single-open-order-delivery-status > .delivery-button {
  color: #0055ff;
  background: #e2edff;
  border-radius: 3px;
}

.single-open-order-div > .single-open-order-content {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: 10px 0;
}

.single-open-order-div > .single-open-order-content > .single-order-date-time {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.single-open-order-div > .single-open-order-content > .single-order-id > span {
  color: #888888;
  font-size: 11px;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}

.single-open-order-div > .single-open-order-content > .single-order-id > p {
  color: #030303;
  text-transform: uppercase;
  font-size: 13px;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}

.single-open-order-div > .single-open-order-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .accepted-button,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .packing-button,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .ready-button,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .shipped-button,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .delivered-button,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .cancle-button,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .refund-button {
  font-size: 12px;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  text-transform: capitalize;
  border-radius: 3px;
  color: #fff;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .accepted-button
  > img,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .packing-button
  > img,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .ready-button
  > img,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .shipped-button
  > img,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .delivered-button
  > img,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .cancle-button
  > img,
.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .refund-button
  > img {
  padding: 0 4px 0 0;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .accepted-button {
  background: #10c558;
  padding: 6px !important;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .packing-button {
  background: #ffb303;
  padding: 6px !important;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .ready-button {
  background: #c520f5;
  padding: 6px !important;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .shipped-button {
  background: #03cbff;
  padding: 6px !important;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .delivered-button {
  background: #0a64f9;
  padding: 6px !important;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .cancle-button {
  background: #f55353;
  padding: 6px !important;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-button
  > .refund-button {
  background: #7a7a7a;
  padding: 6px !important;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-status
  > .payment-am-status {
  display: flex;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-status
  > .payment-am-status
  > .payment-paid {
  color: #26be7e;
  font-size: 14px;
  font-family: QuicksandBold, 'Quicksand' !important;
  margin-right: 10px;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-status
  > .payment-am-status
  > .payment-cod-pending {
  color: #f55353;
  font-size: 14px;
  font-family: QuicksandBold, 'Quicksand' !important;
  margin-right: -5px;
  width: 100px;
}

.single-open-order-div
  > .single-open-order-amount
  > .single-open-order-payment-status
  > .payment-am-status
  > p {
  color: #1a1a1a;
  font-size: 14px;
  font-family: QuicksandBold, 'Quicksand' !important;
}

/* single order end */

/* end order history */

/* My information */
.my-information-main-component {
  padding: 40px 0;
  margin-left: 320px;
  width: 70%;
  display: flex !important;
  /* align-items: center ; */
  justify-content: center;
  flex-direction: column;
}

.menu-icon-div {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topBar-xMark {
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 769px) {
  .menu-icon-div-inner,
  .topBar-xMark {
    display: none;
  }
}

.menu-icon {
  width: 30px !important;
  height: 2px !important;
  background-color: black !important;

  margin: 6px !important;
}

.my-information-main-component > .my-information-middle-glass-box-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #ffffff;
  background: #0a64f933;
  border-radius: 4px;
  opacity: 1;
  box-shadow: #0a64f98c 0px 0px 80px 0px;
  padding: 5px !important;
  height: 164px;
}

.my-information-main-component
  > .my-information-middle-glass-box-div
  > .my-info-single-glass {
  background: #ecf3ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  width: 255px;
  height: 144px;
  margin: 1px;
  text-transform: capitalize;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.my-information-main-component
  > .my-information-middle-glass-box-div
  > .my-info-single-glass
  > .my-info-image {
  border: 2px solid #ffffff;
  border-radius: 9px;
  padding: 5px;
  margin-bottom: 15px;
  box-shadow: #000000 0px 0px 3px 0px;
  color: #8c8c8c;
  background: #e2edff;
}

.my-information-main-component
  > .my-information-middle-glass-box-div
  > .my-info-single-glass
  > p {
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 16px;
  color: #686868;
}

.my-information-main-component
  > .my-information-middle-glass-box-div
  > .my-info-single-active-glass {
  background: radial-gradient(
    farthest-side at 6% 14%,
    #6ea5ff 0%,
    #0a64f9 100%
  );
  border-radius: 4px;
  color: #ffffff;
  width: 300px;
  height: 182px;
  margin-bottom: 40px;
}

.my-information-main-component
  > .my-information-middle-glass-box-div
  > .my-info-single-active-glass
  > .my-info-image {
  border: 2px solid #ffffff;
  border-radius: 9px;
  box-shadow: #ffffff 0px 0px 3px 0px;
  color: #fff;
  padding: 7px 9px;
  background: #0a64f9;
  margin-bottom: 20px;
}

.my-information-main-component
  > .my-information-middle-glass-box-div
  > .my-info-single-active-glass
  > p {
  color: #fff;
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 18px;
}

.my-information-bottom-glass-box-div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;
  display: none;
  padding: 5px;
}

.my-information-bottom-glass-box-div > .my-information-bottom-glass-content {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.my-information-bottom-glass-box-div
  > .my-information-bottom-glass-content
  > span {
  color: #707070;
  font-size: 12px;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  width: 70% !important;
}

.my-information-bottom-glass-box-div
  > .my-information-bottom-glass-content
  > .customer-data-and-information {
  margin-top: 20px;
}

.my-information-bottom-glass-box-div
  > .my-information-bottom-glass-content
  > .customer-data-and-information
  > .my-information-single {
  display: flex;
  margin: 10px 0;
}

.my-information-bottom-glass-content
  > .customer-data-and-information
  > .my-information-single
  > span {
  color: #232323;
  font-size: 14px;
  font-family: QuicksandBold, 'Quicksand' !important;
  display: flex;
  flex-wrap: wrap;
}

.my-information-bottom-glass-content
  > .customer-data-and-information
  > .my-information-single
  > span:first-child {
  width: 30%;
  color: #707070;
  font-size: 14px;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
}

.my-information-main-component > .my-info-single-active-glass {
  display: flex;
}

.customer-delivery-address-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
}

.customer-delivery-address-section > .customer-single-delivery-address {
  border: 2px solid #0a64f9;
  border-radius: 6px;
  width: 48%;
  padding: 5px;
  margin: 5px;
}

.customer-delivery-address-section
  > .customer-single-delivery-address
  > .customer-single-delivery-address-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-delivery-address-section
  > .customer-single-delivery-address
  > .customer-single-delivery-address-middle {
  display: flex;
  flex-direction: column;
  margin: 10px 0 5px 0;
}

.customer-delivery-address-section
  > .customer-single-delivery-address
  > .customer-single-delivery-address-middle
  > p {
  color: #232323 !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  font-size: 13px;
  margin: 0 !important;
}

.customer-delivery-address-section
  > .customer-single-delivery-address
  > .customer-single-delivery-address-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.customer-data-and-information-delivery {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -100px;
}

.customer-delivery-address-section
  > .customer-single-delivery-address
  > .customer-single-delivery-address-bottom
  > .btn-remove-delivery-address {
  margin: 0px !important;
  padding: 0px !important;
}

.customer-delivery-address-section
  > .customer-single-delivery-address
  > .customer-single-delivery-address-bottom
  > .btn-remove-delivery-address
  > .remove-delivery-address {
  color: #222222;
  font-size: 13px !important;
  border-bottom: 1px solid #222222;
  border-radius: unset !important;
  text-transform: capitalize;
  font-family: QuicksandRegular !important;
}

.customer-delivery-address-section
  > .customer-single-delivery-address
  > .customer-single-delivery-address-bottom
  > .default-address {
  color: #0a64f9 !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  background: #d2e3ff !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  text-transform: capitalize;
}

.customer-delivery-address-section
  > .customer-single-delivery-address
  > .customer-single-delivery-address-bottom
  > .set-default-address {
  color: #0a64f9 !important;
  font-family: QuicksandSemiBold, 'Quicksand' !important;
  background: none !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  text-transform: capitalize;
}

.modal-form-single-input .css-1thjcug {
  background-color: #fff;
}

/* end my information */

/* start cart and payment*/

.main-cart-top-bar {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;
  z-index: 5;
}

.quickvee-logo-cart-top-bar {
  width: 148px;
  height: 44px;
  margin-right: 100px;
}

.top-cart-bar-button {
  color: #232323 !important;
  font-family: QuicksandBold, 'Quicksand' !important;
  text-transform: capitalize !important;
}

.top-cart-bar-button:hover {
  background: none !important;
}

.search-location-button:hover {
  background: none !important;
}

.quickvee-payement-stepper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f9f9f9 !important;
  padding: 20px;
}

.quickvee-payement-stepper > span {
  color: #878787 !important;
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 18px !important;
  margin: 0 10px;
  cursor: not-allowed;
}

.quickvee-payement-stepper > p {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0px;
  transition: all 0.3s ease-in-out;
}

.quickvee-payement-stepper > .active-payment-component {
  color: #0a64f9 !important;
  cursor: pointer;
}

.quickvee-payement-stepper > .active-payment-stepper-status {
  width: 10px;
  height: 10px;
  background-color: #0a64f9;
  border-radius: 50%;
  margin: 0px;
}

@media (max-width: 480px) {
  .active-payment-stepper-status {
    width: 5px !important;
    height: 5px !important;
  }
}
.quickvee-payement-stepper > .active-payment-stepper-range {
  color: #0a64f9 !important;
  background-color: #0062ff !important;
  height: 3px;
}

.quickvee-payement-stepper > hr {
  width: 10%;
  margin: 0px;
  height: 2px;
  background-color: #c4c4c4 !important;
  border: none;
  transition: background-color 0.3s ease-out;
}

.quickvee-payment-component-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
  flex-wrap: wrap;
}

.payment-stepper-cart {
  width: 75%;
  display: flex;
  justify-content: center;
  /* min-height: calc(100vh - 200px); */
}

.payment-stepper-cart > .payment-stepper-wishlist-item {
  width: 65%;
  border-right: 1px solid #e8e8e8;
  padding: 0 20px;
  /* min-height: calc(100vh - 200px); */
}

.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-stepper-wishlist-item-topper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0px -15px; */
}

.payment-cart-remove-from-cart-wishlist-modal {
  display: flex;
}

.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-stepper-wishlist-item-topper
  > .selected-count {
  color: #232323;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.q-payment-selected-wishlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.q-payment-selected-wishlist span {
  color: #595959;
  font-size: 14px;
  font-family: QuicksandBold !important;
  border-right: 1px solid #414141;
  padding: 0 20px;
  cursor: pointer;
}

.q-payment-selected-wishlist img {
  cursor: pointer;
}

.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-stepper-wishlist-item-topper
  > .selected-wishlist
  > .selected-delete-all {
  color: red;
  margin-left: 20px;
}

.payment-stepper-cart > .payment-stepper-checkout-item {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  padding-bottom: 15px;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-item-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #232323;
  font-size: 20px;
  font-family: QuicksandBold !important;
  margin-bottom: 15px;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content {
  display: flex;
  flex-direction: column;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > div {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 5px 0;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > div
  > span {
  color: #595959;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > div
  > p {
  padding: 0 !important;
  margin: 0 !important;
  color: #595959;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > div
  > p
  img {
  margin-right: 5px;
  cursor: pointer;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .quickvee-checkout-final-amount
  > span,
.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .quickvee-checkout-final-amount
  > p {
  color: #222222;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .quickvee-checkout-final-amount {
  margin: 15px 0px 10px 0px;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .payment-checkout-with-login {
  background: #000000;
  border: 2px solid #000000;
  border-radius: 6px;
  color: #fff;
  text-transform: capitalize;
  font-size: 13.72px;
  margin-bottom: 15px;
  font-family: QuicksandBold !important;
  height: 44px;
}

.payment-checkout-with-login-save-and-proceed {
  border: 2px solid #000000 !important;
  border-radius: 6px !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-family: QuicksandBold !important;
  margin: 15px 0 !important;
  height: 44px;
}
.payment-checkout-with-login-pay-button {
  border: 2px solid #000000 !important;
  border-radius: 6px !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-family: QuicksandBold !important;
  margin: 15px 0 !important;
  height: 44px;
}
.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .payment-checkout-as-guest {
  border: 2px solid #000000;
  border-radius: 6px;
  color: #000000;
  text-transform: capitalize;
  font-size: 16px;
  font-family: QuicksandBold !important;
  margin: 15px 0;
  height: 44px;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .payment-checkout-as-guest:hover {
  background-color: #fff !important;
}

.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-all-cart-items {
  margin: 20px 0px;
  overflow-y: auto;
  max-height: 72vh;
  width: 100%;
  overflow-x: hidden;
  /* padding: 0 20px 0 0; */
}

.payment-all-cart-items.overflowed {
  padding-right: 20px;
}

.payment-all-cart-items:not(.overflowed) {
  padding-right: 0;
}

.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-all-cart-items::-webkit-scrollbar {
  width: 3px;
  position: relative;
  right: 10px;
}

/* Track */
.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-all-cart-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-all-cart-items::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.payment-stepper-cart
  > .payment-stepper-wishlist-item
  > .payment-all-cart-items::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

.payment-all-cart-add-more-products {
  border-radius: 6px;
  float: right;
  color: #000000;
  font-family: QuicksandBold !important;
  text-transform: capitalize;
  padding: 5px 15px 5px 0px;
  font-size: 16px;
  display: flex;
  align-items: center;
  /* width: 75%; */
}

.payment-page-wishlist-cart-items {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  padding: 10px 16px;
  margin: 0 0 15px 0;
  width: 100%;
  justify-content: space-between;
}

.payment-page-wishlist-cart-items > .payment-page-wishlist-item-image {
  width: 15%;
}

.payment-page-wishlist-cart-items > .payment-page-wishlist-item-content {
  width: 80%;
}

.payment-page-wishlist-cart-items > div {
  display: flex;
  align-items: flex-start;
}

.payment-page-wishlist-cart-items > .payment-page-wishlist-item-image > div {
  position: relative;
  right: 0px;
  padding: 10px;
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-page-wishlist-cart-items
  > .payment-page-wishlist-item-image
  > div
  > img {
  width: 66px;
}

.payment-page-wishlist-cart-items
  > .payment-page-wishlist-item-image
  > .checkbox {
  position: relative;
  top: -2px;
  right: -2px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  width: 15px;
}

.payment-page-wishlist-cart-items
  > .payment-page-wishlist-item-image
  > .checkbox-checkmark {
  position: relative;
  top: -5px;
  right: 15px;
  padding: 10px 10px 10px 10px;
  background-color: rgb(226, 226, 226);
  border-radius: 2px;
  z-index: 1;
}

.payment-page-wishlist-cart-items
  > .payment-page-wishlist-item-image
  > .checkbox:checked
  ~ .checkbox-checkmark {
  /* background-color: #14AE2D; */
  background-image: url('../Assets/Payment/checked.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.payment-page-wishlist-item-content {
  display: flex;
  flex-direction: column;
}

.payment-modal-outer-div .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 500px !important;
}

.payment-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 25px;
}

.payment-modal.out-of-stock-products {
  padding: 20px 50px;
}

@media (max-width: 768px) {
  .payment-modal.out-of-stock-products {
    padding: 20px 25px;
  }
}

.payment-modal .payment-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-modal .payment-modal-content img {
  margin-bottom: 15px;
  width: 80px;
  height: auto;
}

.payment-modal .payment-modal-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}

.payment-modal .payment-modal-button button {
  width: 48%;
  padding: 10px;
  font-size: 15px;
  font-family: QuicksandBold, 'Quicksand' !important;
  background-color: #fff;
  outline: none;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.payment-modal .payment-modal-button button:first-child {
  border: 1px solid #232323;
}

.payment-modal .payment-modal-content span {
  text-align: center;
  color: #212121;
  font-family: QuicksandBold, 'Quicksand' !important;
  font-size: 18px;
  padding: 0 10px;
}

.payment-page-wishlist-item-content > .top-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.payment-page-wishlist-item-content > .top-content > p {
  color: #212121;
  font-size: 16px;
  font-family: QuicksandBold !important;
  margin-right: 45px;
}

.payment-page-wishlist-item-content > .middle-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  width: 70%;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .increment-decremnt-button {
  display: flex;
  align-items: center;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .increment-decremnt-button
  > button {
  border: none;
  background: #0a64f9;
  border-radius: 4px;
  color: #fff;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .increment-decremnt-button
  > button:disabled {
  background: #80aef7;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .increment-decremnt-button
  > span {
  margin: 0px 50%;
  font-family: QuicksandSemiBold !important;
  color: #000000;
  width: 13%;
  font-size: 19px;
  text-align: center;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .amount-calculation
  > span {
  color: #2e2e2e;
  font-size: 18px;
  font-family: QuicksandBold !important;
  margin: 0px 10px;
}

.payment-page-wishlist-item-content
  > .middle-content
  > .amount-calculation
  > del {
  font-size: 14px;
  color: #989898;
  font-family: QuicksandMedium !important;
}

.payment-page-wishlist-item-content > .bottom-dropdowns-for-categories {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.payment-page-wishlist-item-content > .bottom-dropdowns-for-categories {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.payment-page-wishlist-item-content > .bottom-dropdowns-for-categories > div {
  width: 45%;
  margin: 0 10px 0 0;
}

.payment-stepper-cart > .payment-stepper-customer-detail-section {
  display: flex;
  flex-direction: column;
  width: 65%;
  border-right: 1px solid #d3d3d3;
  padding: 0 20px;
}

.payment-billing-customer-form {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 20px 0px;
  margin-left: auto;
}

.payment-billing-customer-form > h1 {
  color: #212121;
  text-transform: capitalize;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.payment-billing-customer-form p {
  color: #222222;
  font-size: 12px;
  /* margin-bottom: 15px  !important; */
  font-family: QuicksandMedium !important;
}
.id-verigfication-description {
  margin-bottom: 15px !important;
}
.payment-billing-inputs-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.payment-billing-input-outer-div {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  /* align-items: center; */
  width: 48.5%;
}

.payment-billing-input-outer-div-for-all {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  /* align-items: center; */
  width: 100%;
}

/* .payment-billing-input-outer-div:nth-child(2){
  align-items: flex-end;
} */
.payment-billing-input {
  max-width: 100%;
  font-size: 12px !important;
  background: #ffffff;
  font-family: QuicksandMedium, 'Quicksand' !important;
  background: #ffffff !important;
  border: 1px solid #d3d3d3 !important;
  border-radius: 6px !important;
}

.payment-billing-input .css-s2lqj8 {
  color: #595959 !important;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
}

.payment-billing-input:focus-within {
  border: 1px solid #595959 !important;
}

.payment-billing-input::placeholder {
  color: #595959 !important;
}

.c-payment-billing {
  width: 100% !important;
}

.payment-billing-city-state-update-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #595959 !important;
}

.payment-billing-city-state-update-inputs > div:first-child {
  margin: 0px 15px 0px 0px !important;
  width: 48%;
}

.payment-billing-city-state-update-inputs > div:first-child .css-14x1nlk {
  color: #595959 !important;
  font-family: QuicksandMedium, Quicksand !important;
  font-size: 13px !important;
  top: -5px;
}

.payment-billing-modal-form-single-item {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 50%;
}

.payment-billing-modal-form-single-item .css-1rgmeur {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: QuicksandSemiBold !important;
  top: -3px !important;
}

.payment-billing-modal-form-single-item .css-14x1nlk {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: QuicksandSemiBold !important;
  top: -3px !important;
}

.payment-billing-modal-form-single-item .css-17gq4vl.css-17gq4vl.css-17gq4vl {
  color: #000000 !important;
  font-size: 14px !important;
  font-family: QuicksandSemiBold !important;
  padding: 17px 10px 2px 10px;
}

.billing-info-delivery-address-update-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  /* margin: 5px 0 15px 0; */
  width: 100%;
  /* margin-bottom: 15px !important; */
}

.billing-info-delivery-address-update-form .billing-date-time {
  width: 100% !important;
}

.billing-info-delivery-address-update-form > div {
  width: 100%;
}

.billing-info-delivery-address-update-form > div:nth-child(1) {
  margin: 0px 10px 15px 0;
}

.billing-info-delivery-address-update-form > div:nth-child(2) {
  margin: 0px 10px;
}

.billing-info-delivery-address-update-form > div:nth-child(3) {
  margin: 0px 0px 0px 5px;
}

.billing-info-delivery-address-update-form .css-13sljp9 {
  width: 100% !important;
  /* padding-top:8px; */
}

.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
  padding: 12px 10px 6px 9px !important;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root {
  background: none !important;
  font-size: 14px !important;
  padding-top: 4px !important;
}

.payment-stepper-single-billing-info-detail > .payment-billing-save-button {
  float: right;
  color: #ffffff;
  font-family: QuicksandBold !important;
  background: #0a64f9;
  padding: 5px 60px;
  border-radius: 6px;
  text-transform: capitalize !important;
}
.payment-stepper-single-billing-info-detail {
  padding-top: 24px;
}

.payment-stepper-single-billing-info-detail
  > .payment-billing-save-button:hover {
  float: right;
  color: #ffffff;
  font-family: QuicksandBold !important;
  background: #0a64f9;
  padding: 5px 60px;
  border-radius: 6px;
  text-transform: capitalize !important;
}

.apply-coupon-modal-button {
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  border: 1px solid #176cf8;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.apply-coupon-listing-btn {
  color: #0a64f9;
  font-family: QuicksandBold !important;
  font-size: 14px !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.apply-coupon-listing-btn p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply-coupon-modal-button.coupon-selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 15px #0a64f94d;
  border: 1px solid #176cf8;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.apply-coupon-modal-button > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px;
}

.apply-coupon-modal-button > div > p {
  color: #0a64f9;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
  margin: 0;
  padding: 0;
}

.apply-coupon-modal-button > div > span {
  color: #222222;
  font-size: 12px;
  font-family: QuicksandSemiBold !important;
  margin: 0;
  padding: 0;
}

.basic-modal,
.basic-modal:focus-visible {
  outline: none;
}

.show-hide-button {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 13px;
  cursor: pointer !important;
  color: #222222 !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
  font-size: 13px;
  top: 6px;
}

.modal-forms > .available-coupons-listing {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0;
  width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* opacity: 1; */
}

.coupon-error-on-modal {
  display: flex;
  align-self: flex-start;
  color: red;
  font-size: 16px;
  font-family: 'QuicksandMedium' !important;
  position: relative !important;
  margin-left: 15px !important;
}

.modal-forms
  > .available-coupons-listing
  > .available-coupons-listing-checkbox
  > .available-coupons-checkbox {
  position: relative;
  top: 0px;
  right: -5px;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}

.modal-forms
  > .available-coupons-listing
  > .available-coupons-listing-checkbox
  > .available-coupons-checkbox-checkmark {
  position: relative;
  /* top: 2px; */
  right: 5px;
  padding: 2px 12px 2px 12px;
  background-color: rgb(226, 226, 226);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 2px;
  z-index: 0;
  cursor: pointer;
}

.modal-forms
  > .available-coupons-listing
  > .available-coupons-listing-checkbox
  > .available-coupons-checkbox:checked
  ~ .available-coupons-checkbox-checkmark {
  background-size: cover;
  background-image: url('../Assets/Payment/coupon.svg');
}

.modal-forms > .available-coupons-listing > .available-coupons-listing-content {
  cursor: pointer;
}

.modal-forms
  > .available-coupons-listing
  > .available-coupons-listing-content
  > span {
  color: #232323;
  font-size: 14px;
  font-family: QuicksandBold !important;
  text-transform: uppercase;
}

.modal-forms
  > .available-coupons-listing
  > .available-coupons-listing-content
  > p {
  color: #000000;
  font-size: 12px;
  font-family: QuicksandRegular !important;
}

#modal-modal-description {
  display: flex;
  justify-content: flex-start !important;
  height: auto;
  max-height: 84vh;
  overflow-y: auto;
}

.css-dmmspl-MuiFormGroup-root {
  display: flex !important;
  flex-direction: row !important;
}

.payment-delivery-section > div .css-1h7anqn {
  flex-direction: row !important;
}

.payment-delivery-section > span {
  color: #212121;
  text-transform: capitalize;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.payment-delivery-section > .select-delivery-hour-and-date {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  display: none;
  /* align-items: center;
  justify-content: center; */
}

.select-delivery-hour-and-date .choose-time-on-payment {
  width: 100% !important;
}

.select-delivery-hour-and-date .choose-date-on-payment input {
  position: relative;
  left: 3px;
}

.select-delivery-hour-and-date label {
  color: #595959 !important;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
}

.select-delivery-hour-and-date input {
  /* padding: 12px 10px 6px 9px !important; */
  font-family: QuicksandSemiBold !important;
  /* font-size: 13px !important; */
}

.select-delivery-hour-and-date
  .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  position: relative;
  top: 4px;
  left: 3px;
  padding: 17px 10px 9px 9px !important;
}

.select-delivery-hour-and-date.DeliveryNow-active {
  display: flex;
  animation: fadeOut 0.8s !important;
  height: 0px !important;
  display: none !important;
}

.payment-delivery-section > .DeliveryNow-active {
  display: flex;
  animation: fadeOut 0.8s !important;
  height: 0px !important;
  display: none !important;
}

.payment-delivery-section > .DeliveryLater-active {
  display: flex;
  animation: fadeIn 0.9s;
}

.payment-delivery-section > .select-delivery-hour-and-date > div {
  width: 100% !important;
  margin-right: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: -40px;
    height: 0px;
  }

  100% {
    opacity: 1;
    top: 0px;
    height: 85px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    top: 0px;
    height: 50px;
  }

  100% {
    opacity: 0;
    top: -40px;
    height: 0px;
    display: none;
  }
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 13px !important;
  color: #595959 !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
}

.css-ahj2mt-MuiTypography-root {
  color: #222222 !important;
  font-size: 16px !important;
  font-family: QuicksandMedium, 'Quicksand' !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: none !important;
  /* border-radius: 6px !important; */
  outline: none;
  padding: 0px 12px 0px 0px !important;
}

.payment-stepper-single-billing-info-detail
  > .payment-delivery-address-section {
  display: flex;
  flex-direction: column;
}

.payment-stepper-single-billing-info-detail
  > .payment-delivery-address-section
  > .head-section-of-payment-delivery-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-delivery-address-section
  > .head-section-of-payment-delivery-address
  > span {
  color: #212121;
  text-transform: capitalize;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.payment-delivery-address-section
  > .middle-section-of-payment-delivery-address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.payment-delivery-address-section
  > .middle-section-of-payment-delivery-address
  > p {
  color: #222222;
  font-size: 12px;
  font-family: QuicksandMedium !important;
}

.payment-delivery-address-section
  > .middle-section-of-payment-delivery-address
  > label
  > span:nth-child(2) {
  color: #222222;
  font-size: 13px !important;
  font-family: QuicksandMedium !important;
  line-height: 1 !important;
  margin-left: -7px;
}

.payment-delivery-address-section
  > .bottom-section-of-payment-delivery-address {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.payment-delivery-single-delivery-address {
  padding: 10px 15px;
  /* border: 1px solid #d3d3d3; */
  border-radius: 6px;
  /* margin: 12px 0; */
  /* width: 48%; */
}

.payment-delivery-single-delivery-address > div {
  display: flex;
  /* align-items: flex-end; */
  justify-content: space-between;
}

.payment-delivery-single-delivery-address > .bottom-payment-delivery-address p {
  cursor: pointer;
}

.payment-delivery-single-delivery-address
  > .bottom-payment-delivery-address
  > img {
  object-fit: contain;
  width: 15px;
  cursor: pointer;
}

.payment-delivery-single-delivery-address
  > .top-payment-delivery-address
  > img {
  object-fit: contain;
  width: 20px;
}

.payment-delivery-single-delivery-address > .middle-payment-delivery-address {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0 0 0;
  /* width: 240px; */
}

.payment-delivery-single-delivery-address
  > .middle-payment-delivery-address
  > span {
  color: #232323;
  font-family: QuicksandSemiBold !important;
  font-size: 14px;
}

.payment-delivery-single-delivery-address
  > .middle-payment-delivery-address
  > p {
  color: #212121;
  font-family: QuicksandRegular !important;
  font-size: 14px;
}

.payment-delivery-single-delivery-address
  .bottom-payment-delivery-address
  > Button {
  text-transform: capitalize;
  color: #0a64f9;
  font-family: QuicksandSemiBold !important;
  font-size: 13px;
  border-radius: 4px;
}

.payment-delivery-single-delivery-address
  .bottom-payment-delivery-address
  > span {
  background: #ff57570f;
  border: 0.5px solid #ff5757;
  border-radius: 4px;
  color: #ff5757;
  font-size: 10px;
  /* font-size: clamp(8px, 0.05px + 2.6783vw, 10px) !important; */

  font-family: QuicksandSemiBold !important;
  padding: 0px 10px;
}

.billingaddrss-error-on-payment {
  background: #ff57570f;
  border: 0.5px solid #ff5757;
  border-radius: 4px;
  color: #ff5757;
  font-size: 11px;
  font-family: QuicksandSemiBold !important;
  padding: 6px;
}

.quickvee-tip-selection-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  /* margin: 15px 0; */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
}

@keyframes fadeTipIn {
  0% {
    right: -300px;
    width: 100%;
  }

  100% {
    right: 0;
    width: 100%;
  }
}

.quickvee-tip-custom {
  width: 100%;
  position: relative;
  animation: fadeTipIn 0.6s;
}

.quickvee-tip-percents {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.quickvee-tip-percents span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff 0 0 no-repeat padding-box;
  border: 2px solid #dedede;
  border-radius: 6px;
  color: #1e1e1e;
  font-family: QuicksandBold !important;
  font-size: 14px;
  padding: 9px 0;
}

.quickvee-tip-percents span:not(:last-child) {
  margin: 0 2% 0 0;
}

.quickvee-tip-percents span p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quickvee-tip-percents .custom-tip-with-values {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 12px #0a64f926;
  border: 2px solid #0a64f9;
  border-radius: 6px;
  padding: 2% 3%;
}

.payment-add-update-tip {
  /* visibility: hidden; */
  position: absolute;
  right: 3px;
  top: 8px;
  background-color: #fff;
  color: #0a64f9;
  font-size: 14px;
  font-family: QuicksandBold !important;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

#tip-input:not(:placeholder-shown) ~ .payment-add-update-tip {
  visibility: visible !important;
}

.quickvee-tip-percents .selected-tip {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 12px #0a64f926;
  border: 2px solid #0a64f9;
  border-radius: 6px;
}

.payment-delivery-payment-info-section {
  /* height: 400px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.payment-delivery-payment-info-section > span {
  color: #212121;
  text-transform: capitalize;
  font-size: 20px;
  font-family: QuicksandBold !important;
  margin-bottom: 5px;
}

.payment-delivery-payment-info-section > p {
  margin: 10px 0;
  color: #202020;
  font-size: 13px;
  font-family: QuicksandMedium !important;
}

.payment-delivery-payment-info-section > .quickvee-payment-cards-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.div-for-payment-header-and-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quickvee-single-payment-card {
  background-color: #ffff;
  z-index: 2;
}

.payment-delivery-payment-info-section
  > .quickvee-payment-cards-section
  > .quickvee-single-payment-card {
  padding: 10px 15px;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  margin: 2% 0;
  width: 48%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.single-payment-icon-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.single-payment-detail-select {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.payment-delivery-payment-info-section
  .quickvee-payment-cards-section
  .quickvee-single-payment-card
  .single-payment-detail {
  margin: 5px 0;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 20px !important;
}

.quickvee-payment-cards-section
  .quickvee-single-payment-card
  .single-payment-detail
  > span {
  font-size: 14px;
  font-family: QuicksandSemibold !important;
}

.single-payment-detail > p {
  font-size: 12px;
  text-align: start;
  font-family: QuicksandRegular !important;
}

.payment-delivery-payment-info-section
  .quickvee-payment-cards-section
  .quickvee-single-payment-card
  .single-payment-select {
  display: flex;
  flex-direction: row-reverse;
}

.quickvee-payment-cards-section
  .quickvee-single-payment-card
  .single-payment-select
  > * {
  color: #0a64f9;
  font-family: QuicksandSemiBold !important;
  text-transform: capitalize !important;
  font-size: 15px;
  padding: 0;
  margin: 0px;
}

.payment-card-details-add-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between !important;
  width: 100%;
}

.payment-card-details-add-section > div {
  width: 49.5% !important;
}

.quickvee-old-card-detail {
  display: none;
}

.quickvee-new-add-card-detail {
  display: none;
}

.addoldpayment {
  display: flex;
  animation: fadeInPayment 0.6s !important;
}

.removeoldpayment {
  display: flex;
  animation: fadeOutPayment 0.6s !important;
  width: 0px;
  height: 0px;
  opacity: 0;
  z-index: 0;
}

@keyframes fadeInPayment {
  0% {
    opacity: 0;
    height: 0px;
  }

  100% {
    opacity: 1;
    height: 50px;
  }
}

@keyframes fadeOutPayment {
  0% {
    opacity: 1;
    height: 50px;
    width: 100%;
  }

  10% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    height: 0px;
    width: 0px;
  }
}

.addnewpayment {
  display: flex;
  flex-direction: column;
  animation: fadeInPaymentNew 0.6s !important;
}

.removenewpayment {
  display: flex;
  animation: fadeOutPaymentNew 0.6s !important;
  width: 0px;
  height: 0px;
  opacity: 0;
  z-index: 0;
  display: none;
}
@keyframes fadeInPaymentNew {
  0% {
    opacity: 0;
    height: 0px;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 120px;
  }
}

@media (max-width: 768px) {
  @keyframes fadeInPaymentNew {
    0% {
      opacity: 0;
      height: 0px;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
      height: 200px;
    }
  }
}

@keyframes fadeOutPaymentNew {
  0% {
    opacity: 1;
    height: 80px;
    width: 100%;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    height: 0px;
    width: 0px;
  }
}

.payment-old-radio-select {
  border-radius: 4px !important;
}

.payment-old-radio-select > span:nth-child(1) {
  display: none;
}

.payment-old-radio-select > span:nth-child(2) {
  color: #0a64f9 !important;
  font-size: 14px !important;
  font-family: QuicksandSemiBold !important;
}

.payment-old-radio-select.selected > span:nth-child(2) {
  padding: 5px 10px;
}

/* .payment-card-details-add-section-single-item {
  margin: 5px 10px 5px 0px;
  } */

/* .payment-card-details-card-num {
  width: 275px !important;
} */

/* .payment-card-details-expiredate>div>div {
  width: 130px !important;
  min-width: auto !important;

} */
.payment-card-details-expiredate
  .css-1xhypcz-MuiStack-root
  > .MuiTextField-root {
  min-width: 134px;
}

/* .payment-card-details-cvv {
  width: 130px !important;

} */

.payment-card-details-add-section-single-item {
  overflow: hidden;
}

.payment-card-detail-single-item-date-cvv {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.payment-card-detail-single-item-date-cvv > div {
  width: 49% !important;
}

.payment-card-details-expiredate .css-1gtlbg0 > .MuiTextField-root {
  min-width: 100px !important;
}

.quickvee-new-payment-card {
  width: 100%;
  margin: 10px 0;
}

.payment-card-detail-save-checkbox > span:nth-child(2) {
  color: #222222 !important;
  font-size: 12px !important;
  font-family: QuicksandMedium !important;
}

.payment-radio-heading span:nth-child(2) {
  cursor: pointer;
  font-size: 16px !important;
  font-family: QuicksandBold !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: none;
  -moz-appearance: textfield;
}

.the-state-input-in-cart
  > .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  color: #0a64f9 !important;
}

/* end cart and payment */

/* Start Main Home page Search Bar */
.home-top-bar-search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f6f7f8;
  border-radius: 6px;
  margin-bottom: 180px;
  width: 100%;
}

.home-top-bar-search > .home-top-bar-search-input {
  background: #f6f7f8;
  padding: 3px 20px;
  width: 100% !important;
}

.home-top-bar-search > .home-top-bar-search-input,
.home-top-bar-search > .home-top-bar-search-input:focus,
.home-top-bar-search > .home-top-bar-search-input:active,
.home-top-bar-search > .home-top-bar-search-input:focus-within,
.home-top-bar-search > .home-top-bar-search-input:focus-visible {
  border: none;
  border-color: none !important;
}

.home-top-bar-search > .home-top-bar-search-input::placeholder {
  font-family: QuicksandRegular, 'Quicksand' !important;
  font-size: 15px;
  color: #a8a8a8;
}

.home-top-bar-search-icon {
  rotate: 0deg;
  margin-right: 12px;
}

.home-top-bar-search-input::after,
.home-top-bar-search-input::before {
  border: none !important;
}

/* End Main Home Page Search Bar */

/* start payment page coupon new ui */
.quickvee-shop-coupon {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.quickvee-shop-coupon .payment-summary-apply-btn {
  position: absolute;
  background-color: #fff;
  outline: none;
  border: none;
  color: #0a64f9;
  font-size: 14px;
  font-family: QuicksandBold !important;
  top: 2px;
  right: 2px;
  cursor: pointer;
  height: 52px;
  width: 70px;
}

/* .quickvee-shop-coupon .payment-remove-coupon{
  visibility: hidden;
}
#coupon-input:not(:placeholder-shown) .quickvee-shop-coupon .payment-remove-coupon{
visibility: visible;
} */

.quickvee-shop-coupon .payment-apply-coupon {
  visibility: hidden;
}

#coupon-input:not(:placeholder-shown) ~ .payment-apply-coupon {
  visibility: visible;
}

.quickvee-shop-coupon .payment-summary-remove-btn {
  position: absolute;
  background-color: transparent;
  outline: none;
  border: none;
  color: #f34747;
  font-size: 14px;
  font-family: QuicksandBold !important;
  top: 17px;
  right: 16px;
  cursor: pointer;
}

.quickvee-shop-coupon-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0px 15px 0px;
}

.quickvee-shop-coupon-header span:first-child {
  display: flex;
  align-items: center;

  color: #232323;
  font-size: 14px;
  font-family: QuicksandBold !important;
}

.quickvee-shop-coupon-header span:first-child p {
  color: #979797;
  font-size: 14px;
  margin-left: 5px;
  font-family: QuicksandSemiBold !important;
}

.quickvee-shop-coupon .payment-summary-input {
  width: 100%;
  outline: none;
  border: none;
  background: #ffffff;
  padding: 12px 10px;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  color: #000000;
  font-family: QuicksandSemiBold !important;
  font-size: 14px;
}

.quickvee-shop-coupon .payment-summary-input::placeholder {
  font-family: QuicksandSemiBold !important;
  color: #595959;
  font-size: 12px;
}

.quickvee-shop-coupon .payment-summary-input:not(:placeholder-shown) {
  border: 1px solid #000000;
}

.quickvee-shop-coupon .payment-summary-input:focus {
  border: 1px solid #000000;
}

.quickvee-shop-coupon .payment-summary-input-error {
  display: flex;
  align-items: center;
  /* width: 27%; */
  /* justify-content: center; */
  font-size: 11px !important;
  color: #f34747 !important;
  margin: 5px !important;
  align-self: flex-start;
  font-family: 'QuicksandSemiBold', 'Quicksand' !important;
}

.payment-summary-input-tip {
  width: 100%;
  outline: none;
  border: none;
  background: #ffffff;
  padding: 12px 10px;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  color: #000000;
  font-family: QuicksandSemiBold !important;
  font-size: 14px;
}

.payment-summary-input-tip::placeholder {
  font-family: QuicksandSemiBold !important;
  color: #595959;
  font-size: 12px;
}

.payment-summary-input-tip:not(:placeholder-shown) {
  border: 1px solid #000000;
}

.payment-summary-input-tip:focus {
  border: 1px solid #000000;
}

.payment-summary-input-tip-error {
  font-size: 9px !important;
  color: #f34747 !important;
  margin: 5px !important;
  align-self: flex-start;
  font-family: QuicksandRegular, 'Quicksand' !important;
}

.quickvee-shop-coupon-header .tip-cancle-btn {
  color: #f34747;
  font-family: QuicksandBold !important;
  font-size: 14px;
  cursor: pointer;
}

.custom-tip-section .payment-summary-add-tip {
  position: absolute;
  background-color: #fff;
  outline: none;
  border: none;
  color: #0a64f9;
  font-size: 14px;
  font-family: QuicksandBold !important;
  top: 67px;
  right: 9px;
  cursor: pointer;
}

.billing-date-time .css-1thjcug {
  background: #fff !important;
  border: 1px solid #d3d3d3 !important;
  border-radius: 6px !important;
}

.billing-date-time .css-14x1nlk,
.billing-date-time .css-1rgmeur {
  color: #595959 !important;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
}

.billing-date-time .css-ftr4jk {
  color: #000 !important;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
  padding: 22px 0px 6px 12px !important;
  /* font-family: QuicksandBold !important; */
}

.billing-date-time .css-17gq4vl {
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
  /* padding: 22px 0px 10px 9px; */
}

.css-1rgmeur {
  font-family: QuicksandMedium, Quicksand !important;
}

.select-varient-cart .css-17gq4vl.MuiSelect-select {
  color: #0a64f9 !important;
}

/* end payment page coupon new ui */

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  height: 0px !important;
  padding-bottom: 16px !important;
}

/* dropdown loyalty point  */

.loyalty-customSelect {
  background-image: url('../Assets/Payment/dropdown.svg');
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.loyalty-customSelect {
  border-radius: 8px;
  padding: 8px 40px 8px 15px;
  font-family: QuicksandMedium !important;
  /* margin-right: 8px; */
  background-color: white;
  color: #2e2e2e;
  font-size: 16px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  /* max-height: calc(50% - 96px) !important; */
}

#menu-state {
  height: 300px;
}

.custom-select {
  position: relative;
  /* padding: 8px 15px 8px 15px; */
  font-family: QuicksandMedium !important;
  /* margin-right: 8px; */
  background-color: white;
  color: #2e2e2e;
  font-size: 16px;
  /* width: 200px;  */
  /* border: 1px solid #000; */
  /* border-radius: 8px; */
}

.default-option {
  cursor: pointer;
  padding: 5px 0px 5px 5px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center; /* Align items vertically */
  /* justify-content: space-between; */
}

.option {
  display: flex;
  align-items: center;
  width: 100% !important;
}

.select-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 200px;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 0px 6px 15px #0a64f94d;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 5%;
  margin-left: -100px;
}

.select-ul li:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}
.select-ul li {
  cursor: pointer;
  padding: 12px;
}

.select-ul li:hover {
  background-color: #f0f0f0;
}
.option .filter {
  width: 100% !important;
}

.lablePickup span {
  font-family: 'QuicksandSemiBold' !important;
}

.payment-error-msg {
  font-family: QuicksandBold !important;
  padding: 0px 25px;
}

.load-more-btn {
  /* padding: 6px 16px; */
  font-weight: 500;
  font-size: 15px;
  border-radius: 4px;
  border: 1px solid #0a64f9;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
}

.load-more-btn span {
  height: 25px !important;
  width: 25px !important;
}

body.age-confirmation-modal #root {
  filter: blur(5px);
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.text-right {
  text-align: right;
}

.mb-6 {
  margin-bottom: 24px;
}
