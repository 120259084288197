a {
  text-decoration: none !important;
}

@font-face {
  font-family: QuicksandLight;
  src: url(../Assets/fonts/Quicksand-Light.ttf);
}

@font-face {
  font-family: QuicksandBold;
  src: url(../Assets/fonts/Quicksand-Bold.ttf);
}

@font-face {
  font-family: QuicksandMedium;
  src: url(../Assets/fonts/Quicksand-Medium.ttf);
}

@font-face {
  font-family: QuicksandRegular;
  src: url(../Assets/fonts/Quicksand-Regular.ttf);
}

@font-face {
  font-family: QuicksandSemiBold;
  src: url(../Assets/fonts/Quicksand-SemiBold.ttf);
}

@font-face {
  font-family: QuicksandLight;
  src: url(../Assets/fonts/Quicksand-Light.ttf);
}

/* Start Single Store  */
.quickvee-single-store-top-bar {
  flex-wrap: wrap;
  z-index: 100;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  /* padding-left: 3%;
  padding-right: 3%; */
  background-color: #fff;
  border: 1px solid #ebebeb;
}
@media (max-width: 768px) {
  .quickvee-single-store-top-bar {
    padding: unset;
    padding-left: 3%;
    padding-right: 3%;
  }
}
.quickvee-single-store-top-bar > .quickvee-logo-icon-single-store {
  width: 250px;
}

.quickvee-single-store-offer-coupon {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  width: auto;
  position: relative;

  /* justify-content: center; */
}

.quickvee-single-store-offer-coupon > .quickvee-single-store-offer-button {
  width: 100%;
  background-color: #fff !important;
  padding: 6px 16px !important;
  color: #0a64f9;
  text-transform: uppercase;
  font-size: 16px;
  font-family: QuicksandBold !important;
  transition: all 0.4s ease-in-out;
}

.quickvee-single-store-offer-coupon
  > .quickvee-single-store-offer-button
  > span {
  color: #232323;
  text-transform: uppercase;
  font-size: 16px;
  font-family: QuicksandBold !important;
}

.quickvee-single-store-offer-coupon
  > .quickvee-single-store-offer-button:hover {
  box-shadow: 0px 6px 12px #00000029;
}

.quickvee-single-store-offer-coupon > .quickvee-single-store-offer-detail {
  position: absolute;
  margin-top: 40px;
  /* width: 230px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 12px #00000029;
  padding: 10px 0;
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid #ebebeb;
  background-color: #fff;
  animation: fadeIncoupon 0.3s linear;
  left: 0;
  right: 0;
}

@keyframes fadeIncoupon {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

.quickvee-single-store-offer-coupon
  > .quickvee-single-store-offer-detail
  > .usecoupon-text {
  color: #202020;
  text-transform: capitalize;
  font-size: 16px;
  font-family: QuicksandBold !important;
  margin: 5px 0;
}

.quickvee-single-store-offer-coupon
  > .quickvee-single-store-offer-detail
  > .coupon-copytext {
  margin: 14px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px 2px 26px;
  background: #f1f6ff;
  color: #0a64f9;
  text-transform: uppercase;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.quickvee-single-store-offer-coupon
  > .quickvee-single-store-offer-detail
  > span {
  color: #5d5d5d;
  font-size: 9px;
  font-family: QuicksandSemiBold;
  margin: 2px 0;
}

.quickvee-single-store-top-bar
  > .quickvee-single-store-login-and-wishlist-button {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quickvee-customer-login-main {
  position: relative;
}

.quickvee-customer-login {
  background: #0a64f9 !important;
  border-radius: 6px !important;
  color: #ffffff;
  font-size: 16px !important;
  font-family: QuicksandBold !important;
  text-transform: capitalize;
  padding: 10px 30px;
  margin: 0 5px;
  cursor: pointer;
  text-decoration: none;
  height: 44px;
}

.quickvee-single-store-top-bar
  > .quickvee-single-store-login-and-wishlist-button
  > .quickvee-single-store-cart-button {
  cursor: pointer;
  background: #f6f7f8 !important;
  border-radius: 6px !important;
  padding: 6px 20px !important;
  margin: 0 5px;
  line-height: normal !important;
  color: #232323;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.quickvee-single-store-main-area {
  display: flex;
  /* overflow: hidden; */
}

.quickvee-single-store-left-menu {
  background: #fff;
  position: fixed;
  left: 0;
  width: 250px;
  border-right: 1px solid #ebebeb;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px 0;
  display: block;
  scroll-behavior: smooth;
}

.quickvee-single-store-left-menu::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.quickvee-single-store-left-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
.quickvee-single-store-left-menu::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.quickvee-single-store-left-menu::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

.quickvee-single-store-left-menu > .quickvee-left-menu-single-store-detail {
  background-color: #fff;
  /* margin: 10px 0 0 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: sticky;
  width: 100%;
  right: 0;
  top: 0;
  left: 0; */
  padding: 0px 15px 0px 15px;
}

.quickvee-single-store-logo {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px 10px 0;
  position: sticky;
  top: 0;
  z-index: 25;
}

.quickvee-single-store-logo a img {
  object-fit: contain;
  object-position: center;
  border-radius: 100px;
  border: 2px solid #f2f2f2;
  overflow: hidden;
  text-align: center;
  padding: 10px;
  position: relative;
  width: 100px;
  height: 100px;
  left: 0px;
  top: 0px;
  transition: all 0.5s;
}

.quickvee-single-store-logo.scrolled a img {
  position: relative;
  width: 50px;
  height: 50px;
  left: -83px;
  top: 0px;
}

.quickvee-single-store-logo > span {
  border-radius: 12px;
  color: #fff;
  font-family: QuicksandMedium !important;
  text-transform: uppercase;
  padding: 2px 10px;
  font-size: 13px;
  margin-top: -12px;
  z-index: 1;
}

.quickvee-left-menu-single-store-detail-sticky {
  height: 100%;
  transition: all 0.5s;
}

.quickvee-left-menu-single-store-detail-sticky.scrolled {
  height: 5px;
}

.quickvee-left-menu-single-store-detail-sticky
  > .quickvee-single-store-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 10px;
  position: relative;
  top: 0px;
  right: 0px;
  transition: all 0.5s;
}

.quickvee-left-menu-single-store-detail-sticky
  > .quickvee-single-store-content.scrolled {
  top: -50px;
  right: -31px;
  padding: 0px;
}

.quickvee-left-menu-single-store-detail-sticky
  > .quickvee-single-store-content
  > p {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #2e2e2e;
  text-transform: capitalize;
  font-size: 12px;
  font-family: QuicksandSemibold !important;
  text-align: center;
  margin: 5px 0;
  position: relative;
  right: 0px;
  transition: all 0.5s;
}

/* .quickvee-left-menu-single-store-detail-sticky >.quickvee-single-store-content>p.scrolled{
  right: -19px; 
} */

.quickvee-left-menu-single-store-detail-sticky
  > .quickvee-single-store-content
  > span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232323;
  text-transform: capitalize;
  font-size: 16px;
  text-align: center;
  font-family: QuicksandBold !important;
}

.quickvee-left-menu-single-store-detail > .quickvee-single-store-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quickvee-left-menu-single-store-detail > .quickvee-single-store-content > p {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #2e2e2e;
  text-transform: capitalize;
  font-size: 12px;
  font-family: QuicksandSemibold !important;
  text-align: center;
}

.quickvee-left-menu-single-store-detail
  > .quickvee-single-store-content
  > span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232323;
  text-transform: capitalize;
  font-size: 16px;
  font-family: QuicksandBold !important;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-detail
  > .quickvee-single-store-pickup-delivery {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background: #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-detail
  > .quickvee-single-store-pickup-delivery
  > Button {
  width: 100%;
  padding: 3px 25px;
  background-color: none;
  color: #928c8c;
  font-size: 15px;
  text-transform: capitalize !important;
  transition: all 0.2s ease-in-out;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-detail
  > .quickvee-single-store-pickup-delivery
  > Button {
  width: 100%;
  padding: 3px 25px;
  background-color: #efefef;
  color: #928c8c;
  font-size: 15px;
  text-transform: capitalize !important;
  transition: all 0.2s ease-in-out;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-detail
  > .quickvee-single-store-pickup-delivery
  > .active-delivery-button {
  background: #000;
  color: #ffffff;
  font-size: 15px;
  font-family: QuicksandMedium !important;
  box-shadow: 0px 6px 12px #00000042;
}

.quickvee-single-store-reorder-wishlist {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loading-category-menu-listing-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quickvee-single-store-reorder-wishlist > .quickvee-single-store-link {
  width: 98%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 2px 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.quickvee-single-store-reorder-wishlist > .quickvee-single-store-link-active {
  background: #ffffff !important;
  box-shadow: 0px 6px 16px #0a64f936;
  border: 1px solid #0a64f9;
  border-radius: 5px;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link-active
  > .wishlist-icon {
  background: #0a64f9 !important;
  mask: url('../Assets/Store/FavouriteIcon.svg') no-repeat 0px;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link-active
  > .loyaltyIcon-icon {
  background: #0a64f9 !important;
  mask: url('../Assets/OrderScreen/LoyaltyPointIcon.svg') no-repeat 0px;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link-active
  > span {
  color: #0a64f9 !important;
}

.quickvee-single-store-reorder-wishlist > .quickvee-single-store-link:hover {
  background: #f1f6ff;
  border-radius: 5px;
}

.quickvee-single-store-reorder-wishlist > .quickvee-single-store-link > span {
  color: #2e2e2e;
  font-size: 16px;
  font-family: QuicksandSemiBold !important;
  transition: all 0.2s ease-in-out;
  margin-left: 12px;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link:hover
  > span {
  color: #6e81e2;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link
  > .wishlist-icon {
  background: #2e2e2e;
  width: 20px;
  height: 20px;
  display: inline-block;
  mask: url('../Assets/Store/FavouriteIcon.svg') no-repeat 0px;
  transition: all 0.2s ease-in-out;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link:hover
  > .wishlist-icon {
  background: #6e81e2;
  mask: url('../Assets/Store/FavouriteIcon.svg') no-repeat 0px;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link
  > .loyaltyIcon-icon {
  background: #2e2e2e;
  width: 20px;
  height: 20px;
  mask: url('../Assets/OrderScreen/LoyaltyPointIcon.svg') no-repeat 0px;
  transition: all 0.2s ease-in-out;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link:hover
  > .loyaltyIcon-icon {
  background: #6e81e2;
  mask: url('../Assets/OrderScreen/LoyaltyPointIcon.svg') no-repeat 0px;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link
  > .reorder-icon {
  background: #2e2e2e;
  width: 20px;
  height: 20px;
  mask: url('../Assets/Store/ReorderIcon.svg') no-repeat 0px;
  transition: all 0.2s ease-in-out;
}

.quickvee-single-store-reorder-wishlist
  > .quickvee-single-store-link:hover
  > .reorder-icon {
  background: #6e81e2;
  mask: url('../Assets/Store/ReorderIcon.svg') no-repeat 0px;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-navigations {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: block;
}

.quickvee-left-menu-single-store-navigations::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.quickvee-left-menu-single-store-navigations::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
.quickvee-left-menu-single-store-navigations::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.quickvee-left-menu-single-store-navigations::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-navigations
  > .single-store-all-products {
  font-size: 16px !important;
  font-family: QuicksandBold !important;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-navigations
  > .single-store-product-active {
  background: #f5f5f5;
  border-left: 5px solid #232323;
  font-size: 14px;
  font-family: QuicksandBold !important;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-navigations
  > a {
  width: 100%;
  text-decoration: none;
  color: #2e2e2e;
  font-family: QuicksandSemiBold !important;
  font-size: 14px;
  padding: 10px 0 10px 20px;
  border-left: 5px solid transparent;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}

.quickvee-single-store-left-menu
  > .quickvee-left-menu-single-store-navigations
  .single-store-all-products-active {
  background: #f5f5f5;
  border-left: 5px solid #232323 !important;
  font-family: QuicksandBold !important;
  padding: 13px 10px 10px 20px;
}

.quickvee-single-store-main-area-detail-page {
  margin-left: 15%;
  width: calc(100% - 15%);
  display: flex;
  padding: 10px 20px 0px 20px !important;
  overflow-x: hidden;
}

/* View Full Hours */

.view-full-hour-link {
  text-decoration: underline !important;
  color: #518cfc !important;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
  margin: 0px 0px 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232323;
  text-transform: capitalize;
  font-size: 16px;
  font-family: QuicksandBold !important;
}

.modal-view-full-hours-div:focus-visible {
  outline: none !important;
}

.modal-view-full-hours-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  border-radius: 8px;
}

#modal-view-full-hours-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #ebebeb;
}

#modal-view-full-hours-header > span {
  margin: 0 auto;
  color: #000000;
  font-size: 22px;
  font-family: QuicksandBold !important;
  justify-self: center;
}

#modal-view-full-hours-header > img {
  background-color: #ebebeb;
  padding: 5px;
  cursor: pointer;
}

.modal-view-full-hours-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-view-full-hours-description > .full-hours-data {
  width: 100%;
  overflow-x: hidden;
}

.modal-view-full-hours-description > .full-hours-data > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  padding: 10px 0;
}

.modal-view-full-hours-description > .full-hours-data .view-hour-today {
  box-shadow: 0px 0px 7px #005df0;
  margin: 3px -3px;
  border-radius: 5px;
  width: 102%;
}

.modal-view-full-hours-description > .full-hours-data .view-hour-today-span {
  width: 104px;
}

.modal-view-full-hours-description .full-hours-data .single-time-slot {
  width: 48%;
}

.modal-view-full-hours-description > .full-hours-data > div:nth-child(odd) {
  background: #f9f9f9;
}

.modal-view-full-hours-description > .full-hours-data > div > span {
  text-transform: capitalize;
  width: 100px;
  text-align: center;
  color: #232323;
  font-size: 16px;
  font-family: QuicksandBold !important;
  border-right: 2px solid #c7c7c7;
}

.modal-view-full-hours-description > .full-hours-data > div > p {
  color: #787878;
  font-size: 16px;
  font-family: QuicksandBold !important;
  width: 100%;
  text-align: center;
}

.modal-view-full-hours-description > .full-hours-data > div > p > span {
  display: flex;
  flex-wrap: wrap;
}

.modal-view-full-hours-description > .full-hours-data-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.modal-view-full-hours-description
  > .full-hours-data-detail
  > .single-full-hour-data-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.full-hours-data-detail > .single-full-hour-data-detail > img {
  margin-bottom: 15px;
  width: 60px;
  height: 60px;
}

.full-hours-data-detail > .single-full-hour-data-detail > span {
  color: #232323;
  font-size: 18px;
  font-family: QuicksandBold !important;
}

.full-hours-data-detail > .single-full-hour-data-detail > p {
  color: #707070;
  font-size: 12px;
  font-family: QuicksandBold !important;
  line-height: 20px;
}

/* end full hours modal*/
/* End Single Store  */

/* Start Store Wishlist */
.quickvee-store-topbar-cart {
  width: 450px;
  background: #ffffff;
}

.quickvee-store-topbar-cart > .quickvee-store-cart-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.quickvee-store-topbar-cart > .quickvee-store-cart-header > h1 {
  font-size: 24px;
  font-family: QuicksandBold !important;
  color: #222222;
  text-align: center;
  margin-bottom: 0;
}

.quickvee-store-topbar-cart > .quickvee-store-cart-header > h1 > span {
  font-size: 18px;
  color: #595959;
}

.quickvee-store-topbar-cart > .quickvee-store-cart-header > Button {
  padding: 5px !important;
  background: #f3f3f3 !important;
  min-width: 50px !important;
}

.quickvee-store-topbar-cart > .quickvee-store-cart-checkout-btn {
  display: flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  width: 100%;
  padding: 0px 20px 0px 20px;
}

.delivery-amount-modal-cart .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 55px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.not-select-empty-delivery-content h1 {
  font-family: QuicksandSemiBold !important;
  font-size: 19px;
  color: #232323;
  margin-top: 15px;
}

.delivery-address-not-selected {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 20px;
}

.delivery-amount-button-cart-ok {
  margin-top: 10px;
  align-self: center;
  text-decoration: none;
  text-align: center;
  width: 150px;
  background: #0a64f9;
  padding: 10px 0;
  border-radius: 6px;
  color: #ffffff;
  font-family: QuicksandBold !important;
  font-size: 16px;
  text-transform: capitalize !important;
  cursor: pointer;
  outline: none;
  border: 1px solid #0a64f9;
  margin: 20px;
}

.delivery-amount-modal-cart-text {
  font-family: QuicksandBold !important;
  font-size: 20px;
  text-align: center;
}

.quickvee-store-topbar-cart > .quickvee-store-cart-checkout-btn > a,
.quickvee-store-topbar-cart
  > .quickvee-store-cart-checkout-btn
  > .payment-checkout-with-login,
.quickvee-store-cart-checkout-btn .button-for-minamount-on-delivery {
  text-decoration: none;
  text-align: center;
  width: 100%;
  background: #000000;
  padding: 13px 0px !important;
  border-radius: 6px;
  color: #ffffff;
  font-family: QuicksandBold !important;
  font-size: 16px;
  text-transform: capitalize !important;
  line-height: 1;
  letter-spacing: normal;
  height: 44px;
}

.quickvee-store-cart-checkout-btn .button-for-minamount-on-delivery:hover {
  background: #0a64f9;
}

.quickvee-store-cart-items {
  overflow-y: auto;
  scroll-behavior: smooth;
  /* margin: 10px 0; */
  width: 100%;
  height: 87vh;
}

/* width */
.quickvee-store-cart-items::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.quickvee-store-cart-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
.quickvee-store-cart-items::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.quickvee-store-cart-items::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

.quickvee-store-cart-items > .quickvee-store-cart-single-item {
  padding: 17px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid #e1e1e1;
  position: relative;
}

.quickvee-store-cart-items
  > .quickvee-store-cart-single-item
  > .quickvee-scsi-image {
  border: 1px solid #e1e1e1;
  padding: 10px;
  height: 80px;
}

.quickvee-store-cart-items
  > .quickvee-store-cart-single-item
  > .quickvee-scsi-image {
  object-fit: contain;
}

.quickvee-store-cart-items
  > .quickvee-store-cart-single-item
  > .quickvee-scsi-content {
  width: 310px;
  padding: 0px 10px 0px 17px;
}

.quickvee-store-cart-items
  > .quickvee-store-cart-single-item
  > .quickvee-scsi-content
  > .q-cart-product-name {
  width: 85%;
  display: flex;
  color: #212121;
  font-size: 15px;
  font-family: QuicksandMedium !important;
  margin-bottom: 10px;
  text-transform: capitalize !important;
}

.quickvee-store-cart-items
  > .quickvee-store-cart-single-item
  > .quickvee-scsi-content
  > p {
  color: #232323;
  font-family: QuicksandBold !important;
  font-size: 12px;
  margin: 5px 0;
}

.quickvee-store-cart-items
  > .quickvee-store-cart-single-item
  > .quickvee-scsi-content
  > p
  > span {
  color: #707070;
  margin: 0 2px;
}

.q-cart-product-amount-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0;
}

.q-cart-product-amount-section > .q-cart-product-amount-calculation > span {
  color: #2e2e2e;
  font-family: QuicksandBold !important;
  font-size: 20px;
  margin: 0 4px;
}

.q-cart-product-amount-section > .q-cart-product-amount-calculation > del {
  color: #989898;
  font-family: QuicksandMedium !important;
  font-size: 14px;
  margin: 0 4px;
}

.q-cart-product-amount-update {
  display: flex;
  align-items: center;
}

.q-cart-product-amount-section > .q-cart-product-amount-update > button {
  color: #fff;
  background: #0a64f9;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 6px 16px 6px;
  cursor: pointer;
}

.q-cart-product-amount-section
  > .q-cart-product-amount-update
  > button:disabled {
  opacity: 0.5;
}

.q-cart-product-amount-section > .q-cart-product-amount-update > span {
  color: #000000;
  font-family: QuicksandSemibold !important;
  font-size: 19px;
  margin: 0 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quickvee-scsi-content > .q-cart-product-customize {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px 0;
  position: absolute;
  top: 0px;
  right: 15px;
}

.quickvee-scsi-content > .q-cart-product-customize > span {
  color: #595959;
  font-family: QuicksandBold !important;
  font-size: 16px;
  border-right: 2px solid #595959;
  padding-right: 15px;
  cursor: pointer;
}

.quickvee-scsi-content > .q-cart-product-customize > img {
  padding-left: 15px;
  cursor: pointer;
}

.single-store-empty-cart {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 80vh;
}

.empty-cart-content {
  width: 250px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-cart-content img {
  width: 80px;
  margin-bottom: 20px;
}

.empty-cart-content h1 {
  font-family: QuicksandSemiBold !important;
  font-size: 19px;
  color: #232323;
}

.empty-cart-content p {
  font-family: QuicksandSemiBold !important;
  font-size: 13px;
  color: #878787;
  margin: 15px auto;
}

.empty-cart-content .continue-shopping-on-cart {
  color: #0a64f9;
  width: 200px;
  border: 2px solid #0a64f9;
  background: #fff;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
  text-transform: capitalize !important;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 30px;
}

.continue-shopping-on-cart:hover {
  background: #fff !important;
}

.continue-shopping-on-cart a {
  text-decoration: none;
  color: #0a64f9;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
}

/* End Store cart*/

/*  start single product page */
.quickvee-single-product-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}

.quickvee-single-product-top-section {
  display: flex;
  width: 100%;
}

.quickvee-single-product-image-section {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 45%;
  position: sticky;
  top: 0;
}

.quickvee-single-product-image-section > .quickvee-single-product-main-image {
  position: relative;
  border: 1px solid #e1e1e1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

@media (max-width: 768px) {
  .quickvee-single-product-image-section > .quickvee-single-product-main-image {
    height: unset;
  }
}

.quickvee-single-product-images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;
}

.quickvee-single-product-images .skeleton-for-loading {
  display: flex;
  margin-left: 10px;
}

.quickvee-single-product-images > .quickvee-single-product-images-single-image {
  border: 1px solid #e1e1e1;
  padding: 5px 10px;
  margin: 0 9px 10px 0;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.quickvee-single-product-images
  > .quickvee-single-product-images-single-image
  > img {
  width: 68px;
}

.quickvee-single-product-images
  > .quickvee-single-product-images-single-image:hover {
  border: 1px solid #232323;
}

.quickvee-single-product-image-section
  > .quickvee-single-product-main-image
  > span {
  position: absolute;
  top: 0;
  left: 0;
  /* background: #373737; */
  padding: 10px 10px !important;
  font-size: 17px;
  font-family: QuicksandBold !important;
  color: #ffffff;
  align-self: flex-start;
}

.quickvee-single-product-image-section
  > .quickvee-single-product-main-image
  > .quickvee-single-product-add-to-wishlist-icon {
  background: #efefef;
  padding: 10px;
  align-self: flex-start;
  margin: 6px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.quickvee-single-product-image-section
  > .quickvee-single-product-main-image
  > .quickvee-single-product-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.quickvee-single-product-div img {
  object-fit: cover;
  object-position: center;
  width: 90%;
  height: 90%;
}

/* .quickvee-single-product-div  img:hover{

} */
.quickvee-single-product-detail-section {
  width: 62%;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.quickvee-single-product-detail-section::-webkit-scrollbar {
  width: 0px;
}

.quickvee-single-product-detail-section > .quickvee-single-product-main-detail {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.quickvee-single-product-detail-section
  > .quickvee-single-product-main-detail
  > h1 {
  color: #212121;
  font-size: 22px;
  font-family: QuicksandLight !important;
}

.quickvee-single-product-detail-section
  > .quickvee-single-product-main-detail
  > p
  > span {
  color: #2e2e2e;
  font-size: 24px;
  font-family: QuicksandBold !important;
}

.quickvee-single-product-detail-section
  > .quickvee-single-product-main-detail
  > p
  > del {
  color: #989898;
  font-size: 16px;
  font-family: QuicksandMedium !important;
}

.quickvee-single-product-detail-section > .quickvee-single-product-features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.quickvee-single-product-single-features {
  margin: 5px 0;
}

.quickvee-single-product-single-features > p {
  display: flex;
  color: #232323;
  font-size: 16px;
  font-family: QuicksandBold !important;
}

.single-product-single-features-selection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 12px 0;
}

.single-product-single-features-selection > div > input {
  visibility: hidden;
  width: 0px;
  height: 45px;
}

.single-product-single-features-selection > div > input:checked + label {
  color: #0a64f9;
  background: #ffffff;
  box-shadow: 0px 0px 9px #0a64f94d;
  border: 1px solid #176cf8;
  border-radius: 4px;
  height: 44px;
}

.single-product-single-features-selection > div > label {
  cursor: pointer;
  color: #434343;
  text-transform: capitalize;
  font-size: 14px;
  font-family: QuicksandSemibold !important;
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 10px 14px;
  margin: 8px 15px 8px 0;
  border: 1px solid #efefef00;
  height: 44px;
}

.quickvee-single-product-add-update {
  display: flex;
  margin: 20px 0;
}

.quickvee-single-product-add-update > button {
  background: #0a64f9;
  border-radius: 4px;
  color: #fff;
  padding: 0px 8px !important;
  border: none;
  cursor: pointer;
  height: 44px;
}

.quickvee-single-product-add-update .add-minuus-button {
  display: flex;
  padding: 12px !important;
}

/* .quickvee-single-product-add-update .add-minuus-button img{
  width: 20px;
} */
.quickvee-single-product-add-update > button:disabled {
  opacity: 0.5;
  cursor: auto;
}

.quickvee-single-product-add-update > span {
  margin: 5px 15px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: QuicksandSemiBold !important;
  font-size: 20px;
}

.quickvee-single-product-add-update > .single-patc-btn,
.quickvee-single-product-add-update > .single-patc-btn:hover {
  background: #232323;
  border-radius: 4px;
  color: #ffffff;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
  padding: 5px 20px !important;
  text-transform: capitalize !important;
  margin: 0 20px;
}

.quickvee-single-product-add-update > .single-patc-btn:disabled {
  color: #fff;
  cursor: not-allowed;
  pointer-events: all;
}

.go-to-cart-btn,
.go-to-cart-btn:hover {
  width: 50%;
  background: #232323 !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  font-family: QuicksandSemiBold !important;
  font-size: 14px !important;
  padding: 7.76px 20px !important;
  text-transform: capitalize !important;
  margin: 20px 0 !important;
  height: 44px;
}

.quickvee-single-product-detail-description > span {
  color: #232323;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.quickvee-single-product-detail-description > p {
  margin: 10px 0;
  font-family: QuicksandMedium !important;
  text-align: justify;
}

.quickvee-single-product-detail-description > ul {
  padding: 0 20px;
  font-family: QuicksandMedium !important;
  text-align: justify;
}

.quickvee-single-product-detail-description > ul > li {
  list-style: none;
}

.quickvee-single-product-detail-description > ul li::before {
  content: '\2022';
  color: #232323;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.quickvee-single-product-bottom-section {
  margin: 0 0 20px 0;
}

.quickvee-single-product-bottom-section > span {
  color: #232323;
  font-size: 20px;
  font-family: QuicksandBold !important;
}

.quickvee-single-product-bottom-content-section {
  border: 1px solid #dcdcdc;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  margin: 10px 0;
}

.fbt-products {
  display: flex;
  padding: 20px;
  align-items: center;
}

.fbt-single-product-div {
  width: 100%;
}

.fbt-single-product-div
  > .fbt-single-product
  > .fbt-single-product-content
  > span {
  color: #212121;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
  margin-bottom: 5px;
}

.fbt-single-product-div
  > .fbt-single-product
  > .fbt-single-product-content
  > p {
  color: #2e2e2e;
  font-size: 17px;
  font-family: QuicksandBold !important;
}

.fbt-single-product-div
  > .fbt-single-product
  > .fbt-single-product-content
  > p
  > del {
  color: #989898;
  font-size: 13px;
  font-family: QuicksandMedium !important;
}

.fbt-single-product-div > .fbt-single-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #2ccb61;
}

.fbt-single-product-div > .fbt-single-product > .fbt-single-product-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 10px;
  width: 80%;
}

.quickvee-single-product-bottom-content-section
  > .fbt-products
  > .fbt-single-product-div {
  display: flex;
  flex-direction: column;
}

.fbt-category-selected-items > div {
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.fbt-category-selected-items
  .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  padding: 20px 10px 3px 9px !important;
}

.fbt-category-item {
  font-size: 14px !important;
}

.fbt-category-label > label {
  color: #232323 !important;
  font-size: 14px !important;
  font-family: QuicksandSemiBold !important;
}

.quickvee-single-product-bottom-content-section > .fbt-products-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 5px 10px;
}

.fbt-products-plus-product-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 34%;
}

.fbt-single-product-div > .fbt-single-product-features > div {
  margin: 10px 0;
}

.quickvee-single-product-bottom-content-section > .fbt-products-amount > p {
  font-family: QuicksandRegular !important;
  font-size: 20px;
}

.quickvee-single-product-bottom-content-section
  > .fbt-products-amount
  > Button {
  background: #0a64f9 !important;
  border-radius: 4px;
  font-size: 15px;
  font-family: QuicksandSemiBold !important;
  text-transform: capitalize;
  color: #fff;
  padding: 4px 13px !important;
}

.quickvee-highest-selling-product-section {
  width: 100%;
  height: auto;
  display: inline-block;
}

.quickvee-highest-selling-product-section > span {
  color: #232323;
  font-size: 20px;
  font-family: QuicksandBold !important;
  line-height: 55px;
}

.quickvee-highest-selling-products {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 15px 0;
}

/*  end  single product page */

/* start Single Product */

.q-sv-single-product {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.4s ease-in-out;
  /* margin: 0px 30px 25px 0px; */
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.slick-slider {
  margin: 0 -15px;
}

.slick-initialized .slick-slide {
  padding-right: 20px;
  padding-left: 20px;
}

@media (min-width: 1024px) {
  .q-sv-single-product:hover {
    box-shadow: 0px 8px 22px #00000017;
    border: 1px solid #c6c6c6;
    border-radius: 5px;

    /* height: 290px; */
  }
}

.q-sv-single-product > .q-sv-single-product-top {
  outline: none;
  width: 200px;
  height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 20px; */
  align-self: center;
}

.discout-tag {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 28px;
  border-radius: 4px 0px;
  color: #ffffff;
  font-size: 14px;
  font-family: QuicksandBold !important;
  padding: 5px 10px;
  align-self: flex-start;
}

span.limited-qty-text {
  color: red;
  z-index: 2;
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 14px;
  font-family: QuicksandBold !important;
  width: auto !important;
}

span.limited-quantity-text {
  color: red;
  font-size: 16px;
  font-family: QuicksandBold !important;
  width: auto !important;
}

@media (min-width: 768px) {
  .discout-tag-mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .discout-tag {
    display: none;
  }
  span.limited-qty-text {
    margin-bottom: 6px;
  }
}
.discout-tag-mobile {
  z-index: 2;
  /* position: absolute; */
  /* top: 0px;
  left: 0px; */
  /* height: 18px; */
  border-radius: 0px;
  color: #ffffff;
  font-size: 12px;
  font-family: QuicksandBold !important;
  padding: 2px 7px;
  align-self: flex-start;
}
.q-sv-single-product > .q-sv-single-product-top > img {
  z-index: 1;
  height: 100%;
  /* width: 140px; */
  transition: all 0.4s ease-in-out;
}

/* .q-sv-single-product:hover>.q-sv-single-product-top>img {
  height: 100%;
  width: 100%;
} */

.q-sv-single-product > .q-sv-single-product-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.q-sv-single-product > .q-sv-single-product-bottom > h1 {
  color: #212121;
  font-size: 14px;
  font-family: QuicksandMedium !important;
  text-transform: capitalize !important;
}

/* @media (max-width:768px) { */
.single-product-card-heading {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  /* height: 30px; */
}
/* } */

@media (max-width: 768px) {
  .single-product-card-heading {
    height: 30px;
  }
}
.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-amount {
  margin: 4px 0;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-amount
  > span {
  color: #0a64f9;
  font-size: 18px;
  font-family: QuicksandBold !important;
  margin-right: 10px;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-amount
  > del {
  color: #888888;
  font-size: 14px;
  font-family: QuicksandMedium !important;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase
  > Button {
  width: 75%;
  display: flex !important;
  align-items: center !important;
  /* justify-content: space-around !important; */
  background: #0a64f9;
  border-radius: 4px;
  color: #ffffff;
  /* font-size: 14px; */
  font-size: clamp(10px, 0.05px + 2.6783vw, 14px) !important;
  font-family: QuicksandMedium !important;
  text-transform: capitalize !important;
  padding: 6px 8px 6px 8px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
}
@media (min-width: 806px) and (max-width: 822px) {
  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-purchase
    > Button {
    font-size: 12px !important;
  }
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase
  > Button
  p {
  /* margin-left: 5%; */
  white-space: nowrap;
  /* font-size: clamp(12px, 0.1px + 0.98783vw, 14px) !important; */
  font-size: 14px;
}

.q-sv-single-product .out-of-stock-btn {
  background-color: rgb(255, 255, 255) !important;
  color: red !important;
  border: 1px solid red;
}

.out-of-stock-btn-single-product-page {
  margin: 24px 0 !important;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(255, 255, 255) !important;
  color: red !important;
  border: 1px solid red !important;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  font-family: QuicksandMedium !important;
  text-transform: capitalize !important;
  height: 44px !important;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase
  > .remove-wish {
  background: #fff5f5;
  border-radius: 4px;
  padding: 7px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.remove-wish img {
  width: 20px;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase
  > .add-wish {
  display: flex;
  background: #eef4ff;
  border-radius: 4px;
  cursor: pointer;
  height: 44px;
  position: relative;
  width: 38px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .q-sv-single-product
    > .q-sv-single-product-bottom
    > .q-sv-single-product-bottom-purchase
    > .add-wish {
    margin-top: 5px;
  }
}

.q-sv-single-product .single-p-select-variant-btn {
  width: 75%;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  background: #fff;
  /* font-size: 14px; */
  font-size: clamp(10px, 0.05px + 2.6783vw, 14px) !important;
  font-family: QuicksandMedium !important;
  border-radius: 4px;
  padding: 8px 0px;
  text-align: center;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase
  > .product-qti-button {
  color: #000;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
  cursor: auto;
  background: #fff !important;
  border-radius: 4px;
  width: 75%;
  padding: 7px;
  height: 44px;
  border: 1px solid #000;
}

.product-qti-button .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #fff;
}

.q-sv-single-product-bottom-purchase
  > .product-qti-button
  > .add-remove-cart-btn {
  background: #000 !important;
  width: auto !important;
  padding: 4px 5px !important;
  box-shadow: none !important;
  min-width: unset !important;
  border: 1px solid #000 !important;
  height: 28px;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase
  > .product-qti-button
  > svg {
  cursor: pointer;
}

.q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase
  > .product-qti-button
  > span {
  font-size: 16px;
  font-family: QuicksandMedium !important;
  color: #000000;
}

/* end single product*/

/* Start Featured categories */
.single-store-featured-categories-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.single-store-featured-categories-section > h1 {
  color: #212121;
  font-size: 26px;
  font-family: QuicksandBold !important;
}

.single-store-featured-categories-section > .all-featured-categories {
  margin: 10px 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.featured-categories-scrollable-row {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
}

.single-featured-category {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  width: 250px;
  height: 60px;
  scroll-snap-align: start;
  touch-action: pan-y;
}

.single-featured-category > img {
  width: 45px;
  object-fit: contain;
}

.single-featured-category > .single-featured-category-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 10px;
  min-width: 100px;
}

.single-featured-category > .single-featured-category-content > span {
  color: #212121;
  font-size: 12px;
  font-family: QuicksandBold !important;
}

.single-featured-category > .single-featured-category-content > p {
  color: #212121;
  font-family: QuicksandRegular !important;
  font-size: 11px;
}

.quickvee-highest-selling-product-section .slick-slider .slick-prev {
  right: 55px;
  top: -32px;
}

.quickvee-highest-selling-product-section .slick-slider .slick-next {
  right: 16px;
  top: -32px;
}

/* End Featured categories */

/*  Start SingleStoreAllInformationPage */
.SingleStoreAllInformationPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow: hidden; */
}

.single-store-banners-images {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.single-store-banners-images > a {
  margin: 10px 10px 10px 0;
  width: 35%;
}

.single-store-banners-images > a > img {
  width: 100%;
  height: auto;
}

.single-store-sproductListing-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.single-store-sproductListing-section > section {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0px 0px 0;
}

.single-store-sproductListing-section > section > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 200px; */
}

.single-store-sproductListing-section > section > div > a {
  color: #0a64f9;
  font-size: 17px;
  font-family: QuicksandBold !important;
}

.single-store-sproductListing-section
  > section
  > div
  > .search-category-store-image-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

/* .single-store-sproductListing-section>section> div > div  > img{
  cursor: pointer;
} */

.slick-next {
  right: 95px;
  top: -20px;
  z-index: 1;
  /* background: black; */
  width: 31px;
  height: 27px;
}

.slick-next.slick-disabled:before {
  cursor: default !important;

  opacity: 1;
  content: url('../Assets/Store/ScrollRight.svg') !important;
}

.slick-next:before {
  opacity: 1;
  content: url('../Assets/Store/HoverScrollRight.svg') !important;
}

.slick-prev {
  right: 133px;
  left: unset !important;
  top: -20px;
  width: 31px;
  height: 27px;
}

.slick-prev.slick-disabled:before {
  cursor: default !important;
  opacity: 1;
  content: url('../Assets/Store/ScrollLeft.svg') !important;
}

.slick-prev:before {
  opacity: 1;
  content: url('../Assets/Store/HoverScrollLeft.svg') !important;
}

.single-store-sproductListing-section > section > span {
  color: #212121;
  font-size: 24px;

  font-family: QuicksandBold !important;
}

@media (max-width: 820px) {
  .single-store-sproductListing-section > section > span {
    font-size: clamp(22px, 0.05px + 2.1783vw, 24px) !important;
  }
}

.single-store-sproductListing-section > .single-store-sproductListing-products,
.slick-slider .slick-initialized {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  user-select: none;
  /* flex-wrap: wrap; */
}

.single-store-single-category-product-listing-section {
  /* margin: 0px 5px; */
  min-height: calc(100vh - 137px) !important;
}

.single-store-single-category-product-listing {
  /* width: 100%; */
  /* display: flex; */
  /* flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start; */
  margin: 10px -15px;
  /* display: inline-block; */
}

.single-store-single-category-product-listing.loading {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  /* min-height: calc(100vh - 137px) !important;  */
}

.product-in-category {
  width: 20%;
  padding-left: 15px;
  display: inline-block;
  padding-right: 15px;
  float: left;
}

.single-selectCategory-product-listing > .q-single-category-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  position: relative;
}

.single-selectCategory-product-listing > .q-single-category-headings > span {
  color: #212121;
  font-size: 24px;
  font-family: QuicksandBold !important;
}

.select-box {
  position: absolute;
  right: 0px;
  display: block;
  width: 230px;
  margin: 0 auto;
  font-family: QuicksandMedium, sans-serif !important;
  font-size: 18px;
  color: #000000;
  z-index: 10;
}

.select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
}

.select-box__current:focus + .select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}

.select-box__current .select-box__icon {
  /* transform: translateY(-50%) rotate(180deg); */
}

.select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(0deg);
}

.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  opacity: 1;
  transition: 0.2s ease;
}

.select-box__value {
  display: flex;
}

.select-box__input {
  display: none;
}

.select-box__input:checked + .select-box__input-text {
  display: block;
  /* border: 1px solid #000000; */
  border-radius: 7px;
  font-size: 16px;
}

.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 10px;
  background-color: #fff;
  text-align: end;
  padding-right: 48px;
}

.select-box__list {
  z-index: -1;
  overflow: hidden;
  /* border: 1px solid #707070; */
  /* border-radius: 7px 7px 7px 7px; */
  border-radius: 8px;
  box-shadow: 0px 6px 15px #0a64f94d;
  position: absolute;
  top: 38 px;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.select-box__option {
  font-size: 16px;
  font-family: QuicksandMedium !important;
  color: #414141;
  display: block;
  padding: 10px 15px;
  background-color: #fff;
}

.select-box__option:hover,
.select-box__option:focus {
  color: #000000;
  background-color: #fbfbfb;
}

.sort-by:hover,
.sort-by:focus {
  color: #414141 !important;
  background-color: #fbfbfb;
  cursor: auto !important;
}

@-webkit-keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

/*  End SingleStoreAllInformationPage */

/* Start Wishlist */
.single-store-wishlist {
  display: flex;
  flex-wrap: wrap;
}

/* .single-store-wishlist div {
  margin-right: 26px;
  width: 242px;
} */

/* .single-store-wishlist
  > .q-sv-single-product
  > .q-sv-single-product-bottom
  > .q-sv-single-product-bottom-purchase
  > .add-wish {
  right: 19px;
  height: 37px;
} */

.single-store-empty-wishlist {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 80vh;
}

.empty-wishlist-content {
  width: 300px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-wishlist-content img {
  width: 80px;
  margin-bottom: 20px;
}

.empty-wishlist-content h1 {
  font-family: QuicksandSemiBold !important;
  font-size: 19px;
  color: #232323;
}

.empty-wishlist-content p {
  font-family: QuicksandSemiBold !important;
  font-size: 13px;
  color: #878787;
  margin: 15px auto;
  margin-bottom: 15px !important;
}

.empty-wishlist-content .continue-shopping-on-wishlist {
  color: #0a64f9;
  width: 200px;
  border: 2px solid #0a64f9;
  background: #fff;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
  text-transform: capitalize !important;
  background: #ffffff;
  border-radius: 5px;
}

.continue-shopping-on-wishlist:hover {
  background: #fff !important;
}

.continue-shopping-on-wishlist a {
  text-decoration: none;
  color: #0a64f9;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
}

/* End Wishlist */

/* Start Wishlist */
.single-store-loyalty-point {
  display: flex;
  flex-wrap: wrap;
}

.single-store-empty-loyalty-point {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 55vh;
}

.empty-loyalty-point-content {
  /* width: 300px; */
  /* height: 70vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-loyalty-point-content img {
  width: 80px;
  margin-bottom: 20px;
}

.empty-loyalty-point-content h1 {
  font-family: QuicksandSemiBold !important;
  font-size: 19px;
  color: #232323;
}

.empty-loyalty-point-content p {
  font-family: QuicksandSemiBold !important;
  font-size: 13px;
  color: #878787;
  margin: 15px auto;
  margin-bottom: 15px !important;
}

.empty-loyalty-point-content .continue-shopping-on-loyalty-point {
  color: #0a64f9;
  width: 200px;
  border: 2px solid #0a64f9;
  background: #fff;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
  text-transform: capitalize !important;
  background: #ffffff;
  border-radius: 5px;
}

.continue-shopping-on-loyalty-point:hover {
  background: #fff !important;
}

.continue-shopping-on-loyalty-point a {
  text-decoration: none;
  color: #0a64f9;
  font-size: 14px;
  font-family: QuicksandSemiBold !important;
}

/* End Wishlist */

/* Start HomePage */
.home-page-all-sections {
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  margin: 0 auto;
}

.home-page-all-sections > div {
  margin: 10px auto;
  width: 100%;
}

.home-categories {
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-home-categorie {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.single-home-categorie > .single-home-categorie-image-section {
  height: 100px;
  width: 100px;
  border: 1px solid #eee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  /* background-color: red; */
}

.single-home-categorie > span {
  justify-content: center;
  text-align: center;
  margin: 8px 0 0 0;
  font-family: QuicksandMedium !important;
  font-size: 16px;
  color: #131313;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.quickvee-home-stores {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quickvee-home-stores > h1 {
  font-size: 24px;
  color: #212121;
  font-family: QuicksandBold !important;
}

.quickvee-home-all-stores {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
}

.quickvee-home-single-store {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (min-width: 1000px) {
  .quickvee-home-single-store {
    width: 21%;
    margin: 2%;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.quickvee-home-single-store > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.q-store-image-section {
  position: relative;
  border: 2px solid #f2f2f2;
  width: 130px;
  height: 130px;
  border-radius: 100px;
  overflow: hidden;
  text-align: center;
}

.q-store-image-section img {
  width: 90%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);
}

.q-store-content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  text-align: center;
  width: 238px;
}

.q-store-content-section > span {
  margin-top: 10px;
  /* font-size: 18px; */
  font-size: clamp(16px, 0.05px + 2.6783vw, 18px) !important;
  color: #232323;
  font-family: QuicksandBold !important;
}

.q-store-content-section > p {
  font-size: 13px;
  color: #232323;
  font-family: QuicksandMedium !important;
}

.quicvee-home-banner {
  margin: 40px 0;
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
}

.quicvee-home-banner > .quickvee-home-banner-image {
  height: auto;
  width: 100%;
  object-fit: contain;
}

/*  search box  */

.search-data-box::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.search-data-box::-webkit-scrollbar-track {
  background-color: #fff;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
.search-data-box::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.search-data-box::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

.search-data-box {
  z-index: 5;
  left: 250px;
  top: 55px;
  width: 50vw;
  overflow-y: auto;
  position: absolute;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 6px 15px #0a64f94d;
  max-height: 473px;
  overflow-x: hidden;
  padding: 5px 0px;
}

.search-data-box > a {
  display: block;
  width: 100%;
  /* border-bottom: 1px solid #E1E1E1; */
  padding: 10px 10px;
}

.search-bar-link {
  font-family: QuicksandMedium !important;
  /* width: 100%; */
  color: #202020 !important;
  font-size: 14px;
}

.search-bar-link:visited {
  color: #0a64f9 !important;
}

.online-o-page-profile-box {
  text-align: start;
  z-index: 5;
  right: 110px;
  top: 50px;
  width: 140px;
  position: absolute;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  box-shadow: 0px 6px 15px #0a64f94d;
  padding: 5px;
  flex-direction: column;
  position: absolute;
  right: 0;
  margin-right: 2%;
  bottom: 0;
  display: inline-table;
}

.online-o-page-profile-box > .profile-bar-link {
  text-align: start;
  text-decoration: none !important;
  font-family: QuicksandMedium !important;
  width: 100%;
  color: #000000 !important;
  font-size: 14px;
  padding: 7px;
  display: block;
}

.online-o-page-profile-box > .profile-bar-link-logout {
  text-transform: capitalize;
  font-family: QuicksandMedium !important;
  /* margin-left: -31px; */
}

.online-o-page-profile-box > .profile-bar-link-logout:hover {
  background: none !important;
}

.online-o-page-profile-box > .profile-bar-link:nth-child(0) {
  border-bottom: 1px solid #e1e1e1;
}

.online-o-page-profile-box > .profile-bar-link:nth-child(1) {
  border-bottom: 1px solid #e1e1e1;
}

.online-o-page-profile-box > .profile-bar-link:nth-child(2) {
  border-bottom: 1px solid #e1e1e1;
}

.top-bar-button:hover {
  background: none !important;
}

/* End Homepage */

/* Start Footer */
.footer_area {
  width: 100%;
  height: auto;
  background: #f6f6f6;
  padding: 10px 0;
  float: left;
  margin-top: 20px;
}

.footer_area_inner {
  width: 74%;
  height: auto;
  margin: 0 auto;
}

.footer_area_inner.footer_area_inner_menu {
  width: 100%;
  height: auto;
  padding-left: 18%;
  padding-right: 2%;
  display: inline-block;
}

.footer_areaL {
  width: 40%;
  height: auto;
  float: left;
}

.footer_areaL p {
  display: inline-block;
  font-size: 12px;
  color: #707070;
  font-family: 'QuicksandMedium';
}

.footer_areaR {
  width: 60%;
  height: auto;
  float: left;
}

.footer_areaR ul {
  list-style: none;
  float: right;
  padding: 0px;
}

.footer_areaR ul li {
  float: left;
  padding: 0 10px;
  border-right: 1px solid #707070;
  display: inline-block;
}

.footer_areaR ul li:last-child {
  border-right: none;
  padding-right: 0px;
}

.footer_areaR ul li a {
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  color: #707070;
  font-family: 'QuicksandMedium';
}

.footer_areaR ul li a:hover {
  color: #0a64f9;
}

/* End Footer */

/* Start Header*/

.header_area {
  width: 100%;
  height: auto;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 99;
  margin-bottom: 10px;
}

.quickvee_logo {
  width: 148px;
  height: 44px;
}

.header_area_box {
  display: flex;
  align-items: center;
  width: 80%;
  height: auto;
  margin: 0 auto;
}

.header_area_box > .other-header-items {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.logo_area {
  width: auto;
  float: left;
  margin-right: 20px;
}

.home_page_top_category_div {
  /* width: 173px !important; */
  height: auto;
  padding: 0 10px;
  text-align: center;
}

.home_page_top_category_div > .q-single-category-link-home {
  text-decoration: none !important;
  display: inline-block;
}

.q-single-category-link-home > span {
  text-decoration: none !important;
  display: inline-block;
  color: #000 !important;
  margin-top: 10px;
  font-family: QuicksandMedium !important;
}

.home_page_top_category_div_inner {
  width: 100px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 100px;
  overflow: hidden;
  margin: 0 auto;
}

.home_page_top_category_div_inner img {
  width: 100%;
}

.links-div {
  position: relative;
}

.home-page-link-box {
  z-index: 5;
  left: 0px;
  /* top: 50px; */
  width: 130px;
  position: absolute;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  box-shadow: 0px 6px 15px #0a64f94d;
  padding: 5px;
  flex-direction: column;
}

.home-page-link-box > .profile-bar-link {
  text-decoration: none !important;
  font-family: QuicksandMedium !important;
  width: 100%;
  color: #000000 !important;
  font-size: 14px;
  padding: 7px;
}

.home-page-link-box > .profile-bar-link:nth-child(1) {
  border-bottom: 1px solid #e1e1e1;
}

.home-search-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.logo-image-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.logo-image {
  width: 35px;
  border-radius: 50%;
  border: 1px solid rgb(193 193 193);
  margin-right: 20px;
  height: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ..home-search-items:hover {
  background-color: red;
} */

.home-search-items img {
  /* display: none; */
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid rgb(193 193 193);
  /* margin-right: 20px; */
}

.home-search-data-box::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.home-search-data-box::-webkit-scrollbar-track {
  background-color: #fff;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
.home-search-data-box::-webkit-scrollbar-thumb {
  background: #3e85f8;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  outline: 1px solid #fff;
}

/* Handle on hover */
.home-search-data-box::-webkit-scrollbar-thumb:hover {
  background: #0a64f9;
  cursor: pointer !important;
}

.home-search-data-box {
  z-index: 5;
  left: 302px;
  top: 63px;
  width: 35vw;
  overflow-y: auto;
  position: absolute;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 6px 15px #0a64f94d;
  max-height: 432px;
  overflow-x: hidden;
  padding: 5px 0px;
}

.home-search-data-box > div {
  width: 100%;
  /* border-bottom: 1px solid #E1E1E1; */
  padding: 10px 10px;
}

/* End Header*/

/* Start Search category*/
/* .search-category-visit-store-btn {
  background: #0A64F9;
  color: #FFF !important;
  font-size: 15px !important;
  padding: 6px 16px;
  border-radius: 20px;
  border: 1px solid #0A64F9;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.search-category-visit-store-btn:hover {
  background: #FFF;
  color: #0A64F9 !important;
} */

/* Start Search category*/
/* .search-category-visit-store-btn {
  background: #0A64F9;
  color: #FFF !important;
  font-size: 15px !important;
  padding: 6px 16px;
  border-radius: 20px;
  border: 1px solid #0A64F9;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.search-category-visit-store-btn:hover {
  background: #FFF;
  color: #0A64F9 !important;
} */

/* Start Search category*/
.search-category-visit-store-btn {
  background: transparent;

  /* color: #fff !important; */
  color: inherit;
  font-size: 15px !important;
  padding: 6px 10px 6px 10px;
  border-radius: 6px;
  border: 1px solid #0a64f9;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

@media (max-width: 1024px) {
  .search-category-visit-store-btn {
    border: 0;
    padding: 6px 0px 6px 0px;
  }
}

/* .search-category-visit-store-btn:hover {
  background: #fff;
  color: #0a64f9 !important;
} */

.search-category-page-store-detail {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  flex: 1.5 !important;
}
@media (max-width: 480px) {
  .search-category-page-store-detail {
    display: unset !important;
  }
}

.search-category-page-store-detail > .search-category-store-image-div {
  width: 75px !important;
  height: 74px;
  float: left;
  border: 2px solid #f2f2f2;
  border-radius: 100px;
  overflow: hidden;
  padding: 8px;
  text-align: center;
  margin-right: 16px;
  position: relative;
}

.search-category-page-store-detail > .search-category-store-image-div img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-category-page-store-description {
  /* width: 230px;
  height: 74px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-category-page-store-description > span {
  text-transform: capitalize !important;
  font-family: QuicksandBold !important;
}

.search-category-page-store-description > p {
  font-size: 13px;
  text-transform: capitalize;
  font-family: 'QuicksandMedium';
}

/*End Search category*/

.profile-page-user-data-button {
  display: flex;
  align-items: center;
  padding: 3px 15px;
  background: #fff !important;
  color: #232323 !important;
  padding: 0px;
}

.profile-page-user-data-button > img {
  margin-top: -3px !important;
  width: 22px;
}

/* LoyaltyPointsZone Start */
.loyalty-point-zone-page {
  width: 100%;
  margin-left: 250px;
  height: 85vh;
}

.loyalty-point-zone-banner {
  background-image: url('../Assets/OrderScreen/LoyaltyPointBgCoins.png'),
    url('../Assets/OrderScreen/loyaltyPointBackground.png');
  background-position: right center, left top;
  /* background-position: 300px,800px; */
  background-repeat: no-repeat, repeat;
  background-size: contain, cover;

  width: 100%;
  height: 200px;
  display: flex;
  padding: 40px 60px 60px 60px;
}

@media (max-width: 768px) {
  .loyalty-point-zone-banner {
    background-image: url('../Assets/OrderScreen/LoyaltyPointBgCoins.png'),
      url('../Assets/OrderScreen/loyaltyPointBackground.png');
    /* background-position: right center, left top; */
    background-position: 50px, 800px;
    background-repeat: no-repeat, repeat;
    background-size: cover;

    width: 100%;
    height: 200px;
    display: flex;
    padding: 40px 60px 60px 20px;
  }
}

.loyalty-point-zone-top-detail > span {
  font-size: clamp(1rem, 1rem + 3.6783vw, 5rem) !important;
}

.loyalty-point-zone-top-detail > p {
  font-size: clamp(14px, 1px + 4.6783vw, 18px) !important;
}

.loyalty-point-zone-top-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.loyalty-point-zone-top-detail > p {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: QuicksandSemiBold !important;
  font-size: 18px;
  color: #fff;
}

.loyalty-point-zone-top-detail > p > span {
  margin-left: 10px;
}

.loyalty-point-zone-top-detail > span {
  font-size: 4rem;
  color: #fdba18;
  font-family: 'QuicksandBold';
}

.loyalty-point-zone-detail-section {
  margin: 20px 10%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 2%;
}

.loyalty-details-table {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.loyalty-order-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2%;
  /* cursor: pointer; */
}

@media (max-width: 532px) {
  .loyalty-order-details div {
    font-size: 12px;
  }

  .loyalty-order-id {
    font-size: 12px !important;
  }

  .loyalty-pont-decrement {
    font-size: 12px !important;
  }
}

.loyalty-order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5%;
}

.loyalty-point-increament {
  color: #10c558;
  font-size: 15px;
  font-family: QuicksandSemiBold !important;
}

.loyalty-pont-decrement {
  color: #f55353;
  font-size: 15px;
  font-family: QuicksandSemiBold !important;
  margin-top: 5px;
}

.loyalty-order-deatils {
  color: #0a64f9;
  font-size: 16px;
  font-family: QuicksandSemiBold;
}

@media (max-width: 480px) {
  .loyalty-order-deatils {
    display: none !important;
  }
}

.loyalty-order-point {
  color: #2e2e2e;
  font-size: 15px;
  font-family: QuicksandMedium;
}

.loyalty-order-id {
  color: #707070;
  font-size: 14px;
  font-family: QuicksandMedium;
  margin-top: 5px;
}

.loyalty-details-table > p {
  color: #212121;
  font-size: 16px;
  font-family: QuicksandSemiBold !important;
}

.loyalty-order-deatils {
  color: #0a64f9;
  font-size: 16px;
  font-family: QuicksandSemiBold;
}

.loyalty-order-point {
  color: #2e2e2e;
  font-size: 15px;
  font-family: QuicksandMedium;
}

.loyalty-order-id {
  color: #707070;
  font-size: 14px;
  font-family: QuicksandMedium;
  margin-top: 5px;
}

.loyalty-details-table > p {
  color: #212121;
  font-size: 16px;
  font-family: QuicksandSemiBold !important;
}

.loyalty-border-line {
  border-bottom: 1px solid #e9e9e9;
  margin-top: 20px;
  opacity: 1;
}

/* LoyaltyPointsZone End */

/* Store Credit points */
.StoreCredit-point-zone-page {
  width: 100%;
  /* height: auto; */
  margin-left: 250px;
}

@media (max-width: 768px) {
  .StoreCredit-point-zone-page {
    margin-left: 0;
  }
}

.StoreCredit-zone-detail-section {
  margin: 6%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 50px;
  /* padding-bottom: 50%; */
}

.loyalty-border-line {
  border-bottom: 1px solid #e9e9e9;
  margin-top: 20px;
  opacity: 1;
}

/* LoyaltyPointsZone End */
