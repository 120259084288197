/* home page category error */
.home_alert_area {
  width: 74%;
  margin: 20px auto 0px;
}

.alert.home_alert_div {
  background: #f5f9ff;
  padding: 16px;
  border-radius: 5px;
  position: relative;
}

.home_alert_div button {
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  border-radius: 50%;
}

.home_alert_div h3 {
  color: #2e2e2e;
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}

.home_alert_div p {
  color: #707070;
  font-family: 'Poppins', sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.home-search-data-box,
.search-data-box {
  left: 0px;
  top: 50px;
  width: auto;
  right: 0;
}

.relative {
  position: relative;
}

.input-outer-div .custom-input-field {
  border: 1px solid #d3d3d3;
  border-radius: 6px;
}

.search-category-visit-store-btn {
  color: inherit;
}

.home-page-all-sections {
  margin-top: 15px;
}

/* for age confirmation modal */
.age_popup_logo_area {
  width: 35%;
  height: auto;
  margin: 10px auto 0;
  text-align: center;
}
.age_popup_content_area {
  width: 100%;
  height: auto;
}
.age_popup_content_area h4 {
  margin: 30px 0 20px;
  text-align: center;
  font-size: 22px;
  color: #000;
  font-weight: 600;
  font-family: QuicksandBold !important;
}

.age_popup_content_area p {
  font-size: 14px;
  font-family: QuicksandMedium !important;
  color: #232323;
  text-align: center;
  margin-bottom: 10px !important;
  font-weight: 300;
}

.age_popup_content_area span {
  font-weight: 600;
}

.age_popup_button_area {
  width: 100%;
  height: auto;
  text-align: center;
  margin: 20px 0;
}
.modal-body.age-confirmation-modal-body {
  padding: 20px 30px;
}

.age_popup_button_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.age_popup_black_btn,
.age_popup_grey_btn {
  color: #ffffff;
  font-size: 12px;
  padding: 12px 12px;
  text-decoration: none;
  flex: 1;
  text-align: center;
  border-radius: 100px;
  outline: none;
  border: 0;
  font-family: QuicksandBold !important;
  font-weight: 500;
}

.age_popup_black_btn {
  background-color: #000;
  white-space: nowrap;
}
.age_popup_grey_btn {
  background-color: #ccc;
  color: #565656;
  white-space: nowrap;
}

.stateDropdown .modal-form-single-input {
  /* height: 10px; */
}

.flex-1 {
  flex: 1;
}

.single-store-wishlist .q-sv-single-product {
  margin-right: 26px;
  /* width: 242px; */
}

.new__input .modal-form-single-input .css-fi4tqy {
  height: 2.1rem;
  padding: 12px 12px 4px !important;
}

.no-stock-variant-product,
.quickvee-store-cart-items
  > .quickvee-store-cart-single-item
  > .quickvee-scsi-content
  > p.no-stock-variant-product {
  font-size: 15px;
  font-family: QuicksandMedium !important;
  text-transform: capitalize !important;
  color: red;
  font-weight: 500;
  line-height: 1.25;
  margin: 10px 0px;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .payment-checkout-with-login:disabled,
.quickvee-store-topbar-cart > .quickvee-store-cart-checkout-btn > .disable-link,
.quickvee-store-cart-checkout-btn .button-for-minamount-on-delivery:disabled {
  pointer-events: all;
  cursor: not-allowed;
  background-color: rgb(199, 199, 199);
  border-color: rgb(199, 199, 199);
  color: gray;
}

.payment-stepper-cart
  > .payment-stepper-checkout-item
  > .payment-stepper-checkout-content
  > .payment-checkout-as-guest:disabled {
  pointer-events: all;
  cursor: not-allowed;
  border-color: rgb(199, 199, 199);
  color: gray;
}

.payment-page-wishlist-cart-items.error {
  border: 1px solid red;
}

@media (max-width: 480px) {
  .payment-page-wishlist-cart-items.error {
    border: unset;
    border-top: 1px solid red !important;
    border-bottom: 1px solid red !important;
  }
}
.quickvee-store-cart-items > .quickvee-store-cart-single-item.error {
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}

.quickvee-store-cart-checkout-btn {
  display: flex;
  flex-direction: column;
}

.out-of-stock-products-info {
  color: red;
  background-color: #f0f0f0;
  border-radius: 4px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: QuicksandMedium !important;
  font-weight: 500;
  line-height: 1.25;
}
