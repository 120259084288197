a {
  text-decoration: none !important;
}

@font-face {
  font-family: QuicksandLight;
  src: url(../Assets/fonts/Quicksand-Light.ttf);
}

@font-face {
  font-family: QuicksandBold;
  src: url(../Assets/fonts/Quicksand-Bold.ttf);
}

@font-face {
  font-family: QuicksandMedium;
  src: url(../Assets/fonts/Quicksand-Medium.ttf);
}

@font-face {
  font-family: QuicksandRegular;
  src: url(../Assets/fonts/Quicksand-Regular.ttf);
}

@font-face {
  font-family: QuicksandSemiBold;
  src: url(../Assets/fonts/Quicksand-SemiBold.ttf);
}

@font-face {
  font-family: QuicksandLight;
  src: url(../Assets/fonts/Quicksand-Light.ttf);
}

.vectorbanner {
  background: url(../../src/Components/orderSummeryPage/imageNew/OrderSummeryBanner1.png);
  width: 100%;
  height: 30vh;
  background-size: cover;
  margin-top: 50px;
  background-color: #ebf1ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vectorbannerinnerL {
  width: 60%;
  position: relative;
  left: 0%;
  float: left;
  background-size: cover;
  background-position: 100%;
}

.vectorbannerinnerR {

  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vectorbannerinnerR h3 {
  color: white;
}


.pricacy_div {
  width: 100%;
  height: auto;
  display: inline-block;
  margin-bottom: 16px;
}

.pricacy_div p {
  padding: 16px 0 5px;
  margin-bottom: 10px;

  font-size: 16px;
  color: #232323;
  font-family: QuicksandMedium !important;
}

.pricacy_div h1 {
  margin-bottom: 10px;
  padding: 5px 0 5px;
  font-size: 22px;
  color: #232323;
  font-family: QuicksandBold !important;
}

.pricacy_div ul {
  font-size: 16px;
  font-family: QuicksandMedium !important;
  color: #202020;
  padding-left: 20px;
}

.pricacy_div ul li {
  margin-bottom: 12px;
  font-size: 16px;
  color: #232323;
  font-family: QuicksandMedium !important;
}


.pricacy_div div {
  padding: 10px 0 4px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #232323;
  font-family: QuicksandMedium !important;
}


.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

table {
  background-color: transparent;
}

.pricacy_div table tr th,
.pricacy_div table tr td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.pricacy_div table tr th {
  background: #f1f1f1;
}

.lbl-order-delivery {
  font-size: 19px;
  color: #333;
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: QuicksandBold !important;
}

.ul-style {
  margin-left: 20px;
  font-family: QuicksandMedium !important;
}





































/* media query all screen for policy pages */





@media (min-width: 1920px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }
}

@media (max-width: 1440px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }
}

@media (max-width: 1367px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }
}

@media (max-width: 1281px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }
}

@media(max-width: 1280px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }
}

@media (max-width: 1279px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }

}

@media (max-width: 1200px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }
}

@media (max-width: 1024px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }
}

@media (max-width: 1023px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;

  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media (max-width: 950px) {
  .vectorbannerinnerR h3 {
    font-size: 26px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media (max-width: 800px) {
  .vectorbannerinnerR h3 {
    font-size: 20px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media(max-width: 768px) {
  .vectorbannerinnerR h3 {
    font-size: 19px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media (max-width: 767px) {
  .vectorbannerinnerR h3 {
    font-size: 18px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media (max-width: 515px) {
  .vectorbannerinnerR h3 {
    font-size: 18px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media(max-width: 480px) {
  .vectorbannerinnerR {
    font-size: 18px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media (max-width: 479px) {
  .vectorbannerinnerR {
    font-size: 18px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media (max-width: 420px) {
  .vectorbannerinnerR {
    font-size: 18px;
  }
}

@media (max-width: 419px) {
  .vectorbannerinnerR {
    font-size: 18px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}

@media (max-width: 380px) {
  .vectorbannerinnerR {
    font-size: 16px;
  }

  .vectorbannerinnerL {
    display: none;
  }
}